import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetLenderProductListQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getLenderProductListQuery($email: String!, $id_token: String!) {
      getLenderProductList(
        email: $email
        id_token: $id_token
        category_id: 0
        category: ""
        category_group: ""
        sub_category1: ""
        sub_category2: ""
        offset: 0
        count: 1000
      ) {
        id
        lender_id
        lender_email
        category_id
        category
        sub_category1
        sub_category2
        category_group
        title
        product_name
        product_description
        product_markdown_description
        age
        hide_from_search
        guidelines
        markdown_guidelines
        status
        address_line1
        address_line2
        landmark
        latitude
        longitude
        area_id
        area_name
        pin_code
        city
        district
        state
        country
        country_abbreviation
        version
      }
    }
  `;
}
