<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="new-offering"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="new-offering"></app-header-mobile>

<!-- Profile Content -->

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li *ngIf="productID == null || productID == 0" class="breadcrumb-item active" aria-current="page">
          {{ "New Offering" | translate }}
        </li>
        <li *ngIf="productID != null && productID != 0" class="breadcrumb-item active" aria-current="page">
          <a [routerLink]="'/new-offering'">{{ "Offering" | translate }}</a>
        </li>
        <li *ngIf="productID != null && productID != 0" class="breadcrumb-item active" aria-current="page">
          {{ productTitle | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-offerings-sidebar></app-offerings-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <app-my-stepper #cdkStepper [linear]="isLinear" [productId]="productID" [disableTemplateNext]="disableTemplateNext | async" [disableScheduleNext]="disableScheduleNext | async">
          <cdk-step label="Create Offer" [editable]="isEditable" [optional]="false">
            <ng-template cdkStepLabel>
              <span class="icon is-medium">
                <i class="fas fa-concierge-bell"></i>
              </span>
              <span *ngIf="updateFlag == false">Create Offer</span>
              <span *ngIf="updateFlag == true">Update Offer</span>
            </ng-template>
            <p>
              <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                > Additional Information
              </a>
              
            </p>
            <div class="collapse content is-small" id="collapseExample">
              <div class="card card-body">
                You can use this widget to create or update your Offer. This four-step process makes it very easy to create Offer. Ideally, create your Offer and update it until you are satisfied with the design without activating it. If you want to check the design of the Offer, navigate to "My Offerings" and click on the link for your Offer. Once you are satisfied with the design of your Offer, you can then add schedules to your Offer and activate it.
                <ul>
                  <li>
                    <b>Mandatory Fields: </b>(*) is the sign for compulsory fields. To create your Offer, please fill in atleast the fields marked by (*).
                  </li>
                  <li>
                    <b>Categories: </b>Not all Categories will have Sub-Categories. Please ignore if you don't find Sub-Categories in the list.
                  </li>
                  <li>
                    <b>Name Field: </b>The Name field is used to generate a unique URL/Link for your Offer. You can use this link to share it with others on Social Media platforms.
                  </li>
                  <li>
                    <b>Offer Description: </b>You can generate your description automatically using our AI generator. Please check if the AI generated description look good and professional. You can create your Offer and check if it looks good by going to "Offerings" > "My Offering" and clicking on the link of your Offer. This way, you don't need to create another version of your Offer.
                  </li>
                  <li>
                    <b>Guidelines: </b>Guidelines help provide additional details to the users. You can generate your guidelines automatically using our AI generator. Please check if the AI generated description look good and professional. It is considered a contract between you and the Offeree who will book your Offer. Please provide guidelines that Offerees need to adhere to.
                  </li>
                  <li>
                    <b>Additional Properties: </b>Additional properties always help to get more information about your Offer. For example, here, you can share information like your experience with the topic of interest for the Offer.
                  </li>
                  <li>
                    <b>Add Images: </b>Once you create an Offer, you will find the link to "Add Images" enabled. Please add at least one image representing your Offer. Check out different ways to create thumbnail images on the internet.
                  </li>
                  <li>
                    <b>Offer Creation: </b>You can always save your Offer by creating it first. You can revisit your Offer and complete the activation process by going to: "Offerings" > "My Offerings" and clicking on the edit button for your Offer.
                  </li>
                </ul>
              </div>
            </div><br />
            <form name="frmProductDetails" id="frmProductDetails" [formGroup]="frmProductDetails">
<div *ngIf="updateFlag !== true" class="container">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label has-text-primary">(*) fields are required.</label>
                    </div>
                  </div>
                </div>
              </div><br />
              <div class="container">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Category Group (*)</label>
                      <div *ngIf="updateFlag == false">
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="categoryGroup" class="input" name="categoryGroup" id="categoryGroup" (change)="handleCategoriesChange($event)">
                          <option selected value="">Select</option>
                          <option *ngFor="let category of categoryData" [value]="category">{{category}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div *ngIf="updateFlag == true">
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="categoryGroupText" name="categoryGroupText" id="categoryGroupText" class="input" type="text">
                      </p>
                    </div>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Category (*)</label>
                      <div *ngIf="updateFlag == false">
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="category" class="input" name="category" id="category" (change)="handleCategoriesChange($event)">
                          <option selected value="">Select</option>
                          <option *ngFor="let category of filterCategories" [value]="category">{{category}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                      </div>
                      <div *ngIf="updateFlag == true">
                        <p class="control is-expanded has-icons-right">
                          <input formControlName="categoryText" name="categoryText" id="categoryText" class="input" type="text">
                        </p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Sub Category 1</label>
                      <div *ngIf="updateFlag == false">
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="majorCategory" class="input" name="majorCategory" id="majorCategory" (change)="handleCategoriesChange($event)">
                          <option selected value="">Select</option>
                          <option *ngFor="let subcategory1 of filterMajorCategories" [value]="subcategory1">{{subcategory1}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                      <div *ngIf="updateFlag == true">
                        <p class="control is-expanded has-icons-right">
                          <input formControlName="subCategory1Text" name="subCategory1Text" id="subCategory1Text" class="input" type="text">
                        </p>
                        </div>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Sub Category 2</label>
                      <div *ngIf="updateFlag == false">
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="minorCategory" class="input"  name="minorCategory" id="minorCategory" (change)="handleCategoriesChange($event)">
                          <option selected value="">Select</option>
                          <option *ngFor="let subcategory2 of filterMinorCategories" [value]="subcategory2">{{subcategory2}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                      <div *ngIf="updateFlag == true">
                        <p class="control is-expanded has-icons-right">
                          <input formControlName="subCategory2Text" name="subCategory2Text" id="subCategory2Text" class="input" type="text">
                        </p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="field w-100">
                  <label class="label">Title (*)</label>
                  <div class="control has-icons-right">
                      <div *ngIf="updateFlag === false" class="inner-tooltip-icon right-tooltip-addon">
                      <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Title is very important to market your offer. Please add appropriate title so that users can identify your offer uniquely. Max 70 characters.">
                        <i class="fas fa-circle-info"></i>
                      </span>
                      <input formControlName="title" name="title" id="title" class="input" type="text" placeholder="Title" maxlength="70" />
                      </div>
                      <input *ngIf="updateFlag === true" formControlName="title" name="title" id="title" class="input" type="text" placeholder="title" maxlength="70" />
                  </div>
                </div>
                <div class="field w-100">
                  <label class="label">Name (For unique offer Link/URL) (*)</label>
                  <div class="control has-icons-right">
                  <div *ngIf="updateFlag === false" class="inner-tooltip-icon right-tooltip-addon">
                    <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Add unique name for the Offer. Please note that the Offer name should be unique in all Zigwik Offers as this will be used in your Offer Link/URL. The Offer name should not contain any spaces, special characters and capital letters. Max 30 characters.">
                      <i class="fas fa-circle-info"></i>
                    </span>
                    
                    <input formControlName="productName" name="productName" id="productName" class="input form-control" type="text" placeholder="Offer Name (lowercase letters without space or symbols)" maxlength=30
                    [ngClass]="{ 'is-invalid': f.productName.value != null && f.productName.value != '' && f.productName.errors != null }" />
                    <ng-template [ngIf]="f.productName.value != null && f.productName.value != '' && f.productName.errors != null">
                      <br />
                      <span class="has-text-danger">Invalid value for Name</span>
                    </ng-template>
                    </div>
                    <input *ngIf="updateFlag === true" formControlName="productName" name="productName" id="productName" class="input" type="text" placeholder="Offer Name" maxlength="30" />
                  </div>
                </div>
                <div class="field  w-100">
                  <label class="label">Automatic AI Description & Guidelines</label>
</div>
                <div class="field w-100">
                  <div class="control has-icons-right">
                      <textarea formControlName="offerAiPrompt" name="offerAiPrompt" id="offerAiPrompt" class="input" type="text" placeholder="Write your Offer Prompt here." maxlength="2000"></textarea>
                  </div>
                </div>
                <div class="field  w-100">
                  <button id="aiGenerateDescriptionButton" type="button" class="button is-outline is-info is-small" (click)="generateAIDescription()">
                    <span> Generate</span>
                    <span class="icon medium">
                      <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                    </span>
                  </button>
                </div>
                <div class="field  w-100">
                  <label class="label">Description</label>
                  <app-markdown-text-editor [inputValue]="offerInputDescription" (newItemHtmlValue)="addHtmlDescription($event)" (newItemMarkdownValue)="addMarkdownDescription($event)"></app-markdown-text-editor>
                </div>
                <div class="field  w-100">
                  <label class="label">Guidelines</label>
                  <app-markdown-text-editor [inputValue]="offerInputGuidelines" (newItemHtmlValue)="addHtmlGuidelines($event)" (newItemMarkdownValue)="addMarkdownGuidelines($event)"></app-markdown-text-editor>
                </div>
                <div *ngIf="showAddress" id="show-address">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Address 1</label>
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="address1" class="input" type="text" placeholder="address Line 1">
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Address 2</label>
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="address2" class="input" type="text" placeholder="address Line 2">
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Pin Code</label>
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="pincode" class="input" type="text" placeholder="pin code" (focusout)="callGetAreaQuery($event)">
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Landmark</label>
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="landmark" class="input" type="text" placeholder="landmark">
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Area</label>
                      <p class="control is-expanded has-icons-right">
                        <!-- <input formControlName="area" class="input" type="text" name="area" id="area" readonly placeholder="area"> -->
                        <select formControlName="area" class="input w-100" name="area" id="area">
                          <option *ngFor="let area of areaName" [value]="area">{{area}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">City</label>
                      <p class="control is-expanded has-icons-right">
                        <!-- <input formControlName="city" class="input" name="city" id="city" type="text" readonly placeholder="city"> -->
                        <select formControlName="city" class="input" name="city" id="city">
                          <option *ngFor="let item of city" [value]="item">{{item}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">District</label>
                      <p class="control is-expanded has-icons-right">
                        <!-- <input formControlName="district" class="input" name="district" id="district" readonly type="text" placeholder="district"> -->
                        <select formControlName="district" class="input w-100" name="district" id="district">
                          <option *ngFor="let item of district" [value]="item">{{item}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">State</label>
                      <p class="control is-expanded has-icons-right">
                        <input formControlName="state" class="input" type="text" placeholder="state">
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div class="border pb-2">
                  <label class="label p-2">Add Additional properties</label>
                  <div class="field is-horizontal">
                    <div class="field-body p-2">
                      <div class="field mw-25 max-vw-100">
                        <label class="label">Name</label>
                        <div class="inner-tooltip-icon right-tooltip-addon">
                          <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Add name of property that gives value to your offer. Some example names could be, experience, standard, qualification, certificate 1.">
                            <i class="fas fa-circle-info"></i>
                          </span>
                          
                          <input formControlName="productPropertyName" class="input" type="text" placeholder="Name" maxlength=20 />
                          </div>                        
                      </div>
                  <div class="field mw-75 max-vw-100">
                    <label class="label">Value</label>
                    <div class="inner-tooltip-icon right-tooltip-addon">
                      <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Add property value that gives value to your offer. For example, if you have an educational offer and have school class standard as name, you can add value of 5.">
                        <i class="fas fa-circle-info"></i>
                      </span>
                      
                      <input formControlName="productPropertyValue" class="input" type="text" placeholder="Property" maxlength=30 />
                      </div>
                  </div>
                  </div>
                  </div>
                  


                  <div class="field mw-25 max-vw-100 p-2">
                    <button type="button" class="button is-outline is-info is-small" (click)="addAttributesTable()">
                      <span> Add Property</span>
                      <span class="icon medium">
                        <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                      </span>
                    </button>
                  </div>
                  <div class="offering-attribute-schedule">
                  <div class="p-2 table-wrapper-scroll-y my-custom-scrollbar-table" [hidden]="noAttributes">
                  <table class="table table-bordered table-striped mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Property</th>
                        <th scope="col">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let selectedAttribute of selectedAttributes">
                        <td>{{selectedAttribute["attribute_key"]}}</td>
                        <td>{{selectedAttribute["attribute_value"]}}</td>
                        <td><a (click)="deleteAttributeRef(selectedAttribute['attribute_key'])"><i class="fas fa-trash-alt add-schedule-icon-passive"></i></a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
                </div>
                <div class="field is-horizontal pt-4">
                  <div class="field-body">
                <div class="field mw-100 max-vw-100">
                  <label class="label with-tooltip">Hide from search&nbsp;&nbsp;</label><input formControlName="hideFromSearchTag" type="checkbox" data-toggle="toggle" [checked]="hideFromSearchFlag">&nbsp;&nbsp;<span class="icon-tooltip has-tooltip-multiline" data-tooltip="This Checkbox allows you make this offer private so that you can share the link to others but it won't show up when someone is searching it on Zigwik.">
                    <i class="fas fa-circle-info"></i>
                  </span>
                </div>
                </div>
              </div>
                <div class="p-2" *ngIf="updateFlag == true">
                  <label *ngIf="hasSchedules === true"><input type="checkbox" formControlName="license" name="license" id="license" (click)="updateButton()"> Do you really want to update the offer? As this offer already have schedules, updating the offer will deactivate this offer and create another copy of it without any schedules.</label>
                </div>
                <footer class="my-footer pt-2">
                  <div class="buttons">
                    <div class="p-2" *ngIf="updateFlag == false">
                      <button id="createOfferButton" [disabled]="frmProductDetails.invalid" class="button is-outline is-primary is-create" (click)="createProduct()">
                      <span> Create</span>
                    </button>
                    </div>
                    <div class="p-2">
                    <app-image-upload [isOfferImage]=true [productID]="productID"></app-image-upload>
                  </div>
                    <div class="p-2" *ngIf="updateFlag == true">
                      <button id="updateProductButton" [disabled]="updateButtonFlag" class="button is-outline is-primary is-create" (click)="updateProduct()">
                        <span> Update</span>
                      </button>
                    </div>
                    <div  class="p-2">
                    <button [disabled]="disableNext" class="button is-outlined is-primary" (click)="nextStep()">
                      <span> Next</span>
                      <span class="icon medium">
                        <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                      </span>
                    </button>
                  </div>
                  </div>
                </footer>
              </div>
            </form>
          </cdk-step>
          <!-- template Form -->
          <cdk-step label="Slot definition" [editable]="isEditable" [optional]="false">
            <ng-template cdkStepLabel>
              <span class="icon is-medium">
                <fa-icon [icon]="faPerson" size="fa-lg"></fa-icon>
              </span>
              <span>Slot definition</span>
            </ng-template>


            <p>
              <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                > Additional Information
              </a>
              
            </p>
            <div class="collapse content is-small" id="collapseExample">
              <div class="card card-body">
                <ul>
                  <li>
                    <b>Define your day: </b>Day definition is about creating slots you would like on a particular day. You can create more than one such definition that can be attached to a week definition or schedules directly.
                  </li>
                  <li>
                    <b>Define your week: </b>Week definition is required to design your timing per each day of the week. For week definition, it is mandatory to have day definition that can be attached to a day. You can create more than one such definition that can be attached to a schedule directly.
                  </li>
                  <li>
                    <b>Day Definition Name: </b>Name of the day definition is a unique name you can give to your definition per each Offer.
                  </li>
                  <li>
                    <b>Week Definition Name: </b>Name of the week definition is a unique name you can give to your definition per each Offer.
                  </li>
                  <li>
                    <b>Create Button: </b> Create button for day and week definition will be enabled once all required fields are having appropriate values.
                  </li>
                </ul>
              </div>
            </div><br />

<ng-template [ngIf]="this.productDayTemplates != null && this.productDayTemplates.length > 0">
            <label class="label">Available day definition</label>
            <div class="offering">
            <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
              <table class="table table-bordered table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col">Day definition Title</th>
                    <th scope="col">Duration (hr)</th>
                    <th scope="col">Duration (min)</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dayTemplate of productDayTemplates">
                    <td>{{dayTemplate["template_name"]}}</td>
                    <td>{{dayTemplate["slots"][0]["slot_hours"]}}</td>
                    <td>{{dayTemplate["slots"][0]["slot_minutes"]}}</td>
                    <td><a title="Edit Day Definition. You cannot edit the Day Definition if it is already used by a schedule or Week Definition." (click)="loadDayTemplate(dayTemplate['day_template_id'], dayTemplate['template_name'], dayTemplate['slots'])"><i class="fas fa-edit product-icon-passive"></i></a>&nbsp;<a title="Delete Day Definition. You cannot delete the Day Definition if it is already used by a schedule or Week Definition." (click)="deleteDayTemplate(dayTemplate['day_template_id'])"><i class="fas fa-trash-alt add-schedule-icon-passive"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
</ng-template>

            <form [formGroup]="frmScheduleTemplates" name="frmScheduleTemplates" id="frmScheduleTemplates">
              <div class="container">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field w-100">
                      <label class="label has-text-primary">Define your Day</label>
                    </div></div></div></div><br />
              <div class="container">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field w-100">
                      <label class="label">Day definition Name</label>
                      <div class="inner-tooltip-icon right-tooltip-addon">
                        <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please provide a unique title for Day Definition. Please note that in order to create your schedule, you need at least one Day Definition. Max 70 characters.">
                          <i class="fas fa-circle-info"></i>
                        </span>
                        <input formControlName="templateName" class="input" type="text" placeholder="Day definition Name" maxlength="70">
                        </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Duration (Hr)</label>
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="durationHrs" class="input" name="durationHrs" id="durationHrs" (change)="timeSchedularDurationChangeHandler($event)">
                          <option *ngFor="let hr of durationHrs" [value]="hr">{{hr}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                    <div class="field mw-50 max-vw-100">
                      <label class="label">Duration (Min)</label>
                      <p class="control is-expanded has-icons-right">
                        <select formControlName="durationMin" class="input" name="durationMin" id="durationMin" (change)="timeSchedularDurationChangeHandler($event)">
                          <option *ngFor="let min of durationMins" [value]="min">{{min}}</option>
                        </select>
                        <span class="icon is-small is-right">
                          <i class="fas fa-check"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field w-100">
                      <app-day-scheduler [newOfferingInterface]="exportedInterfaces()" #scheduleTemplate></app-day-scheduler>
                    </div>
                  </div>
                </div>
                <div class="p-2" *ngIf="updateDayTemplateFlag == true">
                  <label>Do you really want to update the day definition? This will delete the day definition and create another copy of it.</label>
                </div>
                <footer class="my-footer">
                  <div class="buttons">
                    <div class="p-2" *ngIf="updateDayTemplateFlag == false">
                    <button id="dayTemplateCreationButton" [disabled]="dayTemplateValidCheck" class="button is-outline is-primary has-tooltip-info has-tooltip-left has-tooltip-multiline" (click)="createDayTemplate()" data-tooltip="Creates day definition. In order to create a day definition, please select slots from the above time schedule selection.">
                      <span> Create</span>
                      <span class="icon medium">
                        <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                      </span>
                    </button>
                  </div>
                  <div class="p-2" *ngIf="updateDayTemplateFlag == true">
                    <button id="dayTemplateUpdateButton" [disabled]="dayTemplateValidCheck" class="button is-outline is-primary has-tooltip-info has-tooltip-left has-tooltip-multiline" (click)="createDayTemplate()" data-tooltip="Update day definition.">
                      <span> Update</span>
                      <span class="icon medium">
                        <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                      </span>
                    </button>
                  </div>
                  <div *ngIf="(this.checkedSlotCount | async) == 0">No slot selected</div>
                  <div *ngIf="(this.checkedSlotCount | async) == 1">1 slot selected</div>
                  <div *ngIf="(this.checkedSlotCount | async) > 1">{{this.checkedSlotCount | async}} slots selected</div>
                  </div>

                  <div class="container">
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 slot-selected mr-2 mb-2" *ngFor="let checkedSlot of checkedSlotsForDisplay | async">
                        {{checkedSlot["start_time"]}} to {{checkedSlot["end_time"]}}
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </form>

            <br />
            &nbsp;
            <br />
            <ng-template [ngIf]="this.productWeekTemplates != null && this.productWeekTemplates.length > 0">
              <label class="label">Available week definition</label>
            <div class="offering">
              <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
                <table class="table table-bordered table-striped mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Week definition Title</th>
                      <th scope="col">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let weekTemplate of productWeekTemplates">
                      <td>{{weekTemplate["template_name"]}}</td>
                      <td><a title="Edit Week Definition. You cannot edit the Week Definition if it is already used by a schedule." (click)="loadWeekTemplate(weekTemplate['week_template_id'], weekTemplate['template_name'], weekTemplate['mon_day_template_id'], weekTemplate['tue_day_template_id'], weekTemplate['wed_day_template_id'], weekTemplate['thur_day_template_id'], weekTemplate['fri_day_template_id'], weekTemplate['sat_day_template_id'], weekTemplate['sun_day_template_id'])"><i class="fas fa-edit product-icon-passive"></i></a>&nbsp;<a title="Delete Week Definition. You cannot delete the Week Definition if it is already used by a schedule." (click)="deleteWeekTemplate(weekTemplate['week_template_id'])"><i class="fas fa-trash-alt add-schedule-icon-passive"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
</ng-template>
  
              <form [formGroup]="frmScheduleWeekTemplates" name="frmScheduleWeekTemplates" id="frmScheduleWeekTemplates">
                <div class="container">
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field w-100">
                        <label class="label has-text-primary">Define your Week</label>
                      </div></div></div></div><br />
                <div class="container">
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field w-100">
                        <label class="label">Week definition Name</label>
                        <div class="inner-tooltip-icon right-tooltip-addon">
                          <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please provide a unique title for Week Definition. To create Week Definition, you will need at least one Day Definition so that you can attach to a day in Week Definition. Max 70 characters.">
                            <i class="fas fa-circle-info"></i>
                          </span>
                          <input formControlName="templateName" class="input" type="text" placeholder="Week definition Name" maxlength="70">
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal week-template-web">
                    <div class="field-body">
                      <div class="field mw-25 max-vw-50">
                        <label class="label">Day</label>
                      </div>
                      <div class="field mw-75 max-vw-50">
                        <label class="label">Day definition</label>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal week-template-mobile">
                    <div class="field-body">
                        <label class="label">Select Day definition per day</label>
                      </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Monday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="mondayTemplate" id="mondayTemplate" class="input" name="mondayTemplate" id="mondayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="monday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="monday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Tuesday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="tuesdayTemplate" id="tuesdayTemplate" class="input" name="tuesdayTemplate" id="tuesdayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="tuesday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="tuesday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Wednesday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="wednesdayTemplate" id="wednesdayTemplate" class="input" name="wednesdayTemplate" id="wednesdayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="wednesday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="wednesday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Thursday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="thursdayTemplate" id="thursdayTemplate" class="input" name="thursdayTemplate" id="thursdayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="thursday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="thursday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>                          
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Friday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="fridayTemplate" id="fridayTemplate" class="input" name="fridayTemplate" id="fridayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="friday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="friday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Saturday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="saturdayTemplate" id="saturdayTemplate" class="input" name="saturdayTemplate" id="saturdayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="saturday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="saturday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>                          
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="w-25">
                        <p class="control">
                          Sunday
                        </p>
                      </div>
                      <div class="field mw-75 max-vw-75">
                        <p class="control is-expanded has-icons-right">
                          <select formControlName="sundayTemplate" id="sundayTemplate" class="input" name="sundayTemplate" id="sundayTemplate" (change)="checkWeekTemplateForm()">
                            <option [value]="" id="sunday_0"></option>
                            <option *ngFor="let dayTemplate of productDayTemplates" id="sunday_{{dayTemplate['day_template_id']}}" [value]="">{{dayTemplate["template_name"]}}</option>                          
                          </select>
                          <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                          </span>
                          </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2" *ngIf="updateWeekTemplateFlag === true">
                    <label>Do you really want to update the week definition? This will delete the definition and create another copy of it.</label>
                  </div>
                  <footer class="my-footer">
                    <div class="buttons">
                      <div class="p-2" *ngIf="updateWeekTemplateFlag === false">
                      <button id="weekTemplateCreationButton" [disabled]="weekTemplateValidCheck" class="button is-outline is-primary has-tooltip-info has-tooltip-left has-tooltip-multiline" (click)="createWeekTemplate()" data-tooltip="Creates week definition. This is useful when you create schedules where you can attach this week definition to actual dates when you want to enable your offer.">
                        <span> Create</span>
                        <span class="icon medium">
                          <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                        </span>
                      </button>
                      </div>
                      <div class="p-2" *ngIf="updateWeekTemplateFlag === true">
                        <button id="weekTemplateUpdateButton" [disabled]="weekTemplateValidCheck" class="button is-outline is-primary has-tooltip-info has-tooltip-left has-tooltip-multiline" (click)="createWeekTemplate()" data-tooltip="Update week definition. Deletes the week definition and creates a copy of it.">
                          <span> Update</span>
                        </button>
                        </div>
                      <button [disabled]="disableTemplateNext | async" class="button is-outlined is-primary" (click)="nextStep()">
                        <span> Next</span>
                        <span class="icon medium">
                          <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                        </span>
                      </button>
                    </div>
                  </footer>
                </div>
              </form>
          </cdk-step>
          <cdk-step [editable]="isEditable">
            <!-- using template -->
            <ng-template cdkStepLabel>
              <span class="icon is-medium">
                <fa-icon [icon]="faAddressBook" size="fa-lg"></fa-icon>
              </span>
              <span>Schedule Selection</span>
            </ng-template>
            <p>
              <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                > Additional Information
              </a>
              
            </p>
            <div class="collapse content is-small" id="collapseExample">
              <div class="card card-body">
                A schedule is required to add dates when you want your Offer to be active. You can add more than one schedule and activate the schedule however you want. Please note that if you're going to activate your Offer, you need at least one active schedule.
                <ul>
                  <li>
                    <b>Mandatory Fields: </b>(*) is the sign for compulsory fields. To create your Offer, please fill in atleast the fields marked by (*).
                  </li>
                  <li>
                    <b>Schedule Name: </b>The name of the schedule is a unique name you can give per each Offer.
                  </li>
                  <li>
                    <b>Schedule Type: </b>Schedule type is required to identify how do you want your Offer to be used. There are some special considerations for different types of schedules. Below are the considerations.
                    <ul>
                      <li><b>Weekly Schedule Type: </b>You will need atleast <b>4 days</b> with valid slots in order to create a valid Weekly schedule.</li>
                      <li><b>Monthly Schedule Type: </b>You will need atleast <b>19 days</b> with valid slots in order to create a valid Monthly schedule.</li>
                      <li><b>Custom Schedule Type: </b>All the slots that you add to your schedule will be considered for one booking of an Offer. For example, you create a schedule for 3 months language course where you have slots every weekend. All such slots along with the 3 month period is considered for booking for an Offer.</li>
                    </ul>
                  </li>
                  <li>
                    <b>Offer Price: </b>You can have any price for your Offer depending on your preference. Please note that we will add our commission to the price. Such commission is dynamic in nature.
                  </li>
                  <li>
                    <b>Auto Renewal: </b>You can opt for auto-renewal of the schedule. This is useful when you want to keep your Offer active without any manual intervention. Please note that auto-renewal is only possible if the schedule is auto-deactivated and not when you manually deactivate it.
                  </li>
                  <li>
                    <b>Slot Addition: </b>Once you have Start and End dates defined, you need to add the slots. Slots are a way to attach the day or week definition created in the previous tab to actual dates. Following are the steps to add the slots. Please note that this is a mandatory task for schedules.
                    <ol>
                      <li>Select Slot definition type from the dropdown inside "Add Slots (*)" section.</li>
                      <li>Select the day or week definition based on what you have created from the previous tab.</li>
                      <li>Add start and end dates where you want your day or week definition to be attached.</li>
                      <li>Click on the "Add Slots" button to add the slots.</li>
                    </ol>
                  </li>
                  <li>
                    <b>Min Approval Days: </b>You can provide approval days when you need some time to approve any booking from the Offeree. The default value is 0, meaning the booking will be automatically approved. Suppose you provide a value to this field and don't approve it on time. In that case, the booking will be automatically rejected.
                  </li>
                  <li>
                    <b>Max. Possible Bookings: </b>The Max. Possible Bookings is the number of booking possible per slot. For example, you have Math classes and need ten students to book the slot. Then you need to provide ten as a count in the total count field.
                  </li>
                  <li>
                    <b>Guidelines: </b>Here you can also provide any contractual information required per each schedule. For example, you are running a summer Offer and want additional contractual details for such slots.
                  </li>
                  <li>
                    <b>Cancellation Policy: </b>For each schedule, you can set the number of days to give your users a full or half refund.
                  </li>
                </ul>
              </div>
            </div><br />
            <app-add-schedules [categoryID]="categoryID" [productID]="productID" [scheduleDisableNext]="disableScheduleNext | async" [scheduleIDAsync]="updateScheduleId" (parentStepper)="parentStepper(3)" (parentLoader)="parentLoader()"></app-add-schedules>
          </cdk-step>
          <cdk-step [editable]="isEditable">
            <ng-template cdkStepLabel>
              <span class="icon is-medium">
                <fa-icon [icon]="faCreditCard" size="fa-lg"></fa-icon>
              </span>
              <span>Review & Activation</span>
            </ng-template>
            <p>
              <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                > Additional Information
              </a>
              
            </p>
            <div class="collapse content is-small" id="collapseExample">
              <div class="card card-body">
                <ul>
                  <li>
                    <b>Activation of Offer: </b>Activation of Offer is required so that your Offer is seen by all users of Zigwik. However, in order to activate your Offer, you need to have atleast one schedule activated. You can activate the Offer by the activation button.
                  </li>
                  <li>
                    <b>Activation of Schedule: </b>Activation of schedule is required before you activate your Offer. You can do it by clicking on the activation button for the schedule.
                  </li>
                </ul>
              </div>
            </div><br />
            <app-activate-product [productID]="productID" [newProductID]="newProductID" [newOfferingInterface]="exportedInterfaces()" [productChanged]="productCreated"></app-activate-product>
          </cdk-step>
        </app-my-stepper>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>
