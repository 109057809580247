import { Component, OnInit, NgZone, Renderer2, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';
import { interval } from "rxjs";
import { LoginUserMutation } from "./services/graphql/login.graphql";
import { RegisterMaskedUserMutation } from "./services/graphql/registerMaskedUser.graphql";
import { AttachMaskedUserDetailsMutation } from "./services/graphql/attachMaskedUserDetails.graphql";
import { GetMaskedUserDetailsQuery } from "./services/graphql/getMaskedUserDetails.graphql";
import { SessionService } from "./services/session.service";
import { RandomUserService } from "./services/randomeUser.service";
import { CreateUserMutation } from "./services/graphql/createuser.graphql";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth/auth.service";
import { Apollo, gql } from "apollo-angular";
import firebase from 'firebase/app';
import { SessionConstants, ApplicationConstants } from "src/app/constants/app.constants";
import { GetUserQuery } from "./services/graphql/getuser.graphql";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import AOS from 'aos';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';


declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",

  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "zigwik";
  errorMessage: string;
  isLoggedIn: boolean = true;
  refresh_token = "";
  email = "";
  loginTime = 0;
  user$: Observable<firebase.User | null>;

  redirectUrl = "/";

  public currencies = [
    {
      name: "India INR",
      code: "INR",
      symbolLeft: "₹",
      symbolRight: "",
      decimalPlaces: "2",
      Value: "1",
    }
  ];

  constructor(private translate: TranslateService, 
    private sessionService: SessionService,
    private apollo: Apollo, 
    private loginUserMutation: LoginUserMutation,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private registerMaskedUserMutation: RegisterMaskedUserMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private getMaskedUserDetailsQuery: GetMaskedUserDetailsQuery,
    private randomUserService: RandomUserService,
    private ngZone: NgZone,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private getUserQuery: GetUserQuery,
    private spinner: NgxSpinnerService,
    private createUserMutation: CreateUserMutation,
    private toastr: ToastrService
  ) {
    // set default language and translation
    translate.addLangs(["en"]);
    const setDefaultlanaguage = localStorage.getItem("currency");
    this.user$ = this.authService.user$;

    if (
      localStorage.getItem("lang") === null ||
      localStorage.getItem("lang") === undefined
    ) {
      localStorage.setItem("lang", "en");
      this.translate.use("en");
      translate.setDefaultLang("en");
    } else {
      translate.setDefaultLang(localStorage.getItem("lang"));
    }

    // set default currency
    if (setDefaultlanaguage === null || setDefaultlanaguage === undefined) {
      localStorage.setItem("currency", JSON.stringify(this.currencies[0]));
    }

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
      })
      /** END : Code to Track Page View  using gtag.js */

      //Add dynamic title for selected pages - Start
      router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
          titleService.setTitle(title);
        }
      });
  }

  ngAfterViewInit() {
    const script1 = this._renderer2.createElement('script');
    script1.src = `https://accounts.google.com/gsi/client`;
    script1.async = `true`; 
    script1.defer = `true`; 
    this._renderer2.appendChild(this._document.body, script1);
}

  async ngOnInit() {

    let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
    var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");

    if(token === null || token === "" || email === null || email === "") {
      // @ts-ignore
      window.onGoogleLibraryLoad = () => {  
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: ApplicationConstants.CONST_GOOGLE_ONE_TAP_CLIENT_ID,
          callback: this.handleCredentialResponse.bind(this),
          auto_select: false,
          cancel_on_tap_outside: true,  
        })
        // @ts-ignore
        // google.accounts!.id.renderButton( document!.getElementById('loginBtn')!, { theme: 'outline', size: 'large', width: 200 } )
        // @ts-ignore
        google.accounts.id.prompt(); 
      }
    }

    var currTime = new Date();
    AOS.init({disable: 'mobile'});//AOS - 2
    AOS.refresh();//refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {

      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var email = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "email");
      var phone = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "phone");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");

      var maskedUserDetails = await this.getMaskedUserDetailsQuery.fetch({
        user_id: user_id,
        masked_id: maskedUserData,
        email: email,
        phone: phone
      }).toPromise();

      if(maskedUserDetails != null && maskedUserDetails["data"] != null && maskedUserDetails["data"]["getMaskedUserDetails"] != null) {
        var data = maskedUserDetails["data"];
        if(data["getMaskedUserDetails"] != null && data["getMaskedUserDetails"]["id"] != null) {
          this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["getMaskedUserDetails"]);
        } else {
          this.sessionService.delete(SessionConstants.CONST_MASKED_USER_KEYWORD);
          var maskedUserNew = await this.registerMaskedUserMutation.mutate().toPromise();

          if(maskedUserNew != null && maskedUserNew["data"] != null && maskedUserNew["data"]["registerMaskedUser"] != null) {
            var data = maskedUserNew["data"];
            if(data["registerMaskedUser"] != null && data["registerMaskedUser"]["id"] != null) {
              this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["registerMaskedUser"]);
            }
          }
        }
      }
    } else {
      var maskedUserNew = await this.registerMaskedUserMutation.mutate().toPromise();

      if(maskedUserNew != null && maskedUserNew["data"] != null && maskedUserNew["data"]["registerMaskedUser"] != null) {
        var data = maskedUserNew["data"];
        if(data["registerMaskedUser"] != null && data["registerMaskedUser"]["id"] != null) {
          this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["registerMaskedUser"]);
        }
      }
    }

    if(this.sessionService.checkKey(SessionConstants.CONST_SESSION_TOKEN_KEYWORD) === true) {
      this.loginTime = this.sessionService.getKeyValues("token", "login_time");

      var refreshTokenCycleTimeCap = this.loginTime + ApplicationConstants.CONST_REFRESH_TOKEN_INTERVAL;

      if(currTime.getTime() < refreshTokenCycleTimeCap) {
        this.refresh_token = this.sessionService.get(SessionConstants.CONST_REFRESH_TOKEN_KEYWORD);
        
        this.isLoggedIn = true;
        this.email = this.sessionService.getKeyValues("token", "email");

        let refreshToken = this.sessionService.get(SessionConstants.CONST_REFRESH_TOKEN_KEYWORD);
        let email = this.sessionService.getLocalKeyValues("user", "email");

        await this.refreshTokenCall(email, refreshToken);
      } else {
        this.sessionService.delete("token");
        this.sessionService.delete("user_verified_user_check");
        this.sessionService.deleteLocal("user");
      }
    }
    this.initiateTimer();
  }

  ssoLoginUser(data1) {

    let email = data1.email;
    let refresh_token = data1.refreshToken;

    this.apollo
    .mutate({
      mutation: this.loginUserMutation.document,
      variables: {
        email: email,
        refresh_token: refresh_token,
      },
    })
    .subscribe(
      ({ data }) => {
        var isEmailVerified = data["loginUser"].is_email_verified;
        data["loginUser"].email = email;
        data["loginUser"].valid_user = false;
        data["loginUser"]["login_time"] = (new Date()).getTime();
        if(data["loginUser"] !== null && data["loginUser"]["id_token"] !== null && 
        data["loginUser"]["id_token"] !== "" && 
        data["loginUser"]["user_status"] == "known" && 
        data["loginUser"]["email"] !== null && 
        data["loginUser"]["email"] !== "") {
          data["loginUser"].valid_user = true;
        }
        this.sessionService.setLoginContent(data["loginUser"]);
        if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
          var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
          var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
          this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, email, null);
        }
        this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
        this.apollo
          .query({
            query: this.getUserQuery.document,
            variables: {
              email: email,
              idToken: data["loginUser"]["id_token"],
            },
          })
          .subscribe(
            ({ data }) => {
              this.sessionService.setLocal(
                SessionConstants.CONST_SESSION_USER_KEYWORD,
                data["getUserDetails"]
              );
              this.sessionService.deleteLocal("anonymousUser");
              this.sessionService.deleteLocal("validAnonymousUser");
              this.sessionService.set(
                SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD,
                isEmailVerified
              );
              this.sessionService.delete("redirect_url");
              if(this.redirectUrl !== null) {
                // this.router.navigate([this.redirectUrl]);
                location.href = this.redirectUrl;
              } else {
                // this.router.navigate(["/"]);
                location.href = "/";
              }
              this.spinner.hide();
              
            },
            (error) => {
              this.spinner.hide();
              console.log(error.message);
            }
          );
      },
      (error) => {
        this.createAccount(data1);
      }
    );
  }












  getDateFormateForSearch(date: Date): string {
    let year = date.toLocaleDateString('es', { year: 'numeric' });
    let month = date.toLocaleDateString('es', { month: '2-digit' });
    let day = date.toLocaleDateString('es', { day: '2-digit' });
    return `${year}-${month}-${day}`;
  }

  createAccount(data1) {

    let first_name = data1.displayName.split(" ")[0];
    let last_name = data1.displayName.split(" ")[1];
    var dataParam = {
      first_name: first_name,
      last_name: last_name,
      email: data1.email
    };
    var referral_code_val = "";

    let firebase_user_id = data1.uid;
    let refresh_token = data1.refreshToken;

    this.apollo
      .mutate({
        mutation: this.createUserMutation.document,
        variables: {
          first_name: (dataParam != null && dataParam["first_name"] != null) ? dataParam["first_name"] : "",
          last_name: (dataParam != null && dataParam["last_name"] != null) ? dataParam["last_name"] : "",
          adhaar_card: "@@@",
          pan_card: "@@@",
          birth_date: null,
          terms_accepted: true,
          referral_code: referral_code_val,
          sso: "True",
          verify_email: "True",
          firebase_user_id: firebase_user_id,
          email: dataParam.email,
          phone: "@@@",
          refresh_token: refresh_token,
          password: "",
          account_number: "@@@",
          bank_code: "@@@",
          address_line1: "@@@",
          address_line2: "@@@",
          area_name: "@@@",
          landmark: "@@@",
          pin_code: "@@@",
          city: "@@@",
          district: "@@@",
          state: "@@@",
          description: "Thank for for visiting my profile.",
          markdown_description: "Thank for for visiting my profile."
        },
      })
      .subscribe(
        ({ data }) => {
          data["createUser"].valid_user = false;
          data["createUser"]["login_time"] = (new Date()).getTime();
          if(data["createUser"] !== null && data["createUser"]["id_token"] !== null && 
          data["createUser"]["id_token"] !== "" && 
          data["createUser"]["user_status"] == "known" && 
          data["createUser"]["email"] !== null && 
          data["createUser"]["email"] !== "") {
            data["createUser"].valid_user = true;
          }

          this.sessionService.setLoginContent(data["createUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data["createUser"]["email"], null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: dataParam.email,
                idToken: data["createUser"]["id_token"],
              },
              fetchPolicy: "network-only"
            })
            .subscribe(
              ({ data }) => {
                this.spinner.hide();
                this.sessionService.setLocal(
                  "user",
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                this.router.navigate(["/profile"]);
              },
              (error) => {
                this.spinner.hide();
                this.toastr.error(error.message);
              }
            );
        },
        (error) => {
          this.spinner.hide();
          this.authService.logout();
          if(error.message.includes("[createUser]")) {
            this.toastr.error("The email has already been taken.");
          } else if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(error.message);
          }
        }
      );
  }

  async handleCredentialResponse(response: CredentialResponse) {
    this.spinner.show();

    const idToken = response.credential;

      this.authService.loginWithCredential(idToken).then((user) => {
        this.user$.subscribe((data1) => {
          if(data1.email !== null && data1.email != "") {
            this.ssoLoginUser(data1);
        }
        });
      }).catch((error) => {
        this.user$.subscribe((data1) => {
          if(error.email !== null && error.email != "") {
            this.createAccount(data1);
        }
        });
          // // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
        });
    }



















// collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }


  initiateTimer() {
    interval(ApplicationConstants.CONST_ID_TOKEN_INTERVAL).subscribe
      ((data) => {
        if(this.sessionService.checkKey("token") == true) {
        let idtokenData = JSON.parse(this.sessionService.get("token"));
        if (idtokenData !== null && idtokenData.id_token !== null && 
          idtokenData.email !== null) {
            this.refresh_token = this.sessionService.get(SessionConstants.CONST_REFRESH_TOKEN_KEYWORD);
          this.isLoggedIn = true;
          this.email = idtokenData.email;
            this.refreshTokenCall(this.email, this.refresh_token);
          } else {
            this.isLoggedIn = false;
          }
        } else {
          this.isLoggedIn = false;
        }
      },
      );
  }
  
  async refreshTokenCall(emailId: String, refreshToken: String) {
      try {
      var loginData = await this.apollo
      .mutate({
        mutation: this.loginUserMutation.document,
        variables: {
          email: emailId,
          refresh_token: refreshToken
        },
      }).toPromise();

      if(loginData != null && loginData["data"] != null && loginData["data"]["loginUser"] != null) {
        var data = loginData["data"];

          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, emailId, null);
          }

          data["loginUser"].valid_user = true;
          data["loginUser"].email = emailId;
          this.loginTime = (new Date()).getTime();
          data["loginUser"].login_time = this.loginTime;
          data["loginUser"]["login_time"] = this.loginTime;
            this.sessionService.setLoginContent(data["loginUser"]);
          if(this.sessionService.checkKey("refresh_cookie_flag") == false) {
            this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
            window.location.reload();
          }
      }
      } catch (error) {
          this.sessionService.deleteLocal("user");
          this.sessionService.deleteLocal("anonymousUser");
          this.sessionService.deleteLocal("validAnonymousUser");
          this.sessionService.delete("refresh_cookie_flag");
          this.sessionService.delete("token");
          this.sessionService.delete("user_verified_user_check");
          this.sessionService.delete("referral_code_signup_value");
          window.location.reload();
      }
     }
}
