<form [formGroup]="frmCreateSchedule">
<div class="container">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field w-100">
          <label class="label has-text-primary">Your schedule with your price</label></div></div></div></div><br />
    <div class="container">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field w-100">
            <label class="label">Schedule Name (*)</label>
            <div class="inner-tooltip-icon right-tooltip-addon">
              <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please add appropriate schedule name so that it will help you to identify the schedule in the offering report. Max 70 characters.">
                <i class="fas fa-circle-info"></i>
              </span>
              <input formControlName="scheduleName" class="input" type="text" placeholder="Schedule Name" (change)="changeForm()" maxlength="70" />
              </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-body">
      <div class="field mw-50 max-vw-100">
        <label class="label">Schedule Type (*)</label>
        <p class="control is-expanded has-icons-right">
          <select formControlName="scheduleFrequency" class="input" name="scheduleFrequency" id="scheduleFrequency" (change)="changeForm()">
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">monthly</option>
            <option value="custom">custom</option>
          </select>
          <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span>
        </p>
      </div>
      <div class="field mw-50 max-vw-100">
        <label class="label">Price (₹) (*) 
          </label>
        <div class="inner-tooltip-icon right-tooltip-addon">
          <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please only add actual price for the Offer in this schedule without adding the ₹ symbol. For example: 200.00.">
            <i class="fas fa-circle-info"></i>
          </span>
          <input formControlName="schedulePrice" class="input" type="text" placeholder="Price" (change)="getCalculatedPrice()">
            <span style="color:red" *ngIf="(
              frmCreateSchedule.get('schedulePrice').hasError('pattern') &&
              frmCreateSchedule.get('schedulePrice').touched)">Invalid input.</span>
          </div>
      </div>
      </div>
    </div>
    <div *ngIf="(categoryData | async) != null && (categoryData | async).auto_renewal_possible != null && (categoryData | async).auto_renewal_possible != 0" class="field is-horizontal">
      <div class="field-body">
    <div class="field mw-100 max-vw-100">
      <label class="label with-tooltip">Auto Renewal &nbsp;&nbsp;</label><input formControlName="autoRenewal" #autoRenewal type="checkbox" data-toggle="toggle" [checked]="autoRenewalFlag">&nbsp;&nbsp;<span class="icon-tooltip has-tooltip-multiline" data-tooltip="This Checkbox allows you to auto renew your schedule. The renewal works by deleting schedule that has expired and creating a replica of it. It changes the dates based on the previous date range by starting with the current or future date. This will ensure that you don't have to bother adding schedules again and again if the time slots are fixed for you.">
        <i class="fas fa-circle-info"></i>
      </span>
    </div>
    </div>
  </div>




  <div class="border">
    <label class="label p-2">Add slots (*)</label>
    <div class="field is-horizontal">
      <div class="field-body p-2">
        <div class="field mw-25 max-vw-100">
          <label class="label">Slot definition type</label>
          <p class="control is-expanded has-icons-right">
            <select formControlName="scheduleTemplateType" class="input" name="scheduleTemplateType" id="scheduleTemplateType" (change)="templateChange($event)">
              <option value="NA"></option>
              <option value="day">Day</option>
              <option value="week">Week</option>
            </select>
            <span class="icon is-small is-right">
              <i class="fas fa-check"></i>
            </span>
          </p>
        </div>
    <div class="field mw-75 max-vw-100">
      <label class="label">Slot definition</label>
      <p class="control is-expanded has-icons-right">
        <select formControlName="scheduleTemplateSelect" class="input" name="scheduleTemplateSelect" id="scheduleTemplateSelect" (change)="changeForm()">
          <option value="0"></option>
          <option *ngFor="let template of templateData" [value]="getTemplateId(template)">{{template["template_name"]}}</option>
        </select>
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-body p-2">
  <div class="field mw-50 max-vw-100">
    <label class="label">Start Date</label>
    <div class="form-inline form-group">
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd"
        formControlName="templateStartDate" name="templateStartDate" ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled" #tsd="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn-outline-grey" (click)="tsd.toggle()" type="button">
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="field mw-50 max-vw-100">
    <label class="label">End Date</label>
    <div class="form-inline form-group">
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd"
        formControlName="templateEndDate" name="templateEndDate" ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled" #ted="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn-outline-grey" (click)="ted.toggle()" type="button">
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
  <div class="field mw-25 max-vw-100 p-2">
    <button type="button" class="button is-outline is-info is-small has-tooltip-multiline" data-tooltip="Click on this button to add slots. In this way, you could add different Definitions to date ranges depending on your need." (click)="addTemplatesTable()">
      <span> Add Slots</span>
      <span class="icon medium">
        <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
      </span>
    </button>
  </div>
  <div class="offering-schedule">
  <div class="p-2 table-wrapper-scroll-y my-custom-scrollbar-table" [hidden]="noTemplates">
  <table class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th scope="col">Slot definition Name</th>
        <th scope="col">Slot definition Type</th>
        <th scope="col">Start Date</th>
        <th scope="col">End Date</th>
        <th scope="col">#</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let selectedTemplate of selectedTemplates">
        <td>{{selectedTemplate["template_name"]}}</td>
        <td>{{selectedTemplate["template_type"]}}</td>
        <td>{{selectedTemplate["start_date"]}}</td>
        <td>{{selectedTemplate["end_date"]}}</td>
        <td><a (click)="deleteTemplateRef(selectedTemplate['template_id'], selectedTemplate['template_type'], selectedTemplate['start_date'], selectedTemplate['end_date'])"><i class="fas fa-trash-alt add-schedule-icon-passive"></i></a></td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>

<div class="field is-horizontal">
  <div class="field-body p-2">
    <div class="field mw-25 max-vw-100">
      <label class="label">Min-approval days</label>
      <div class="inner-tooltip-icon right-tooltip-addon">
        <span class="icon-tooltip has-tooltip-multiline" data-tooltip="The approval days is a way where you can decide if you need some time (in days) to approve request for booking your offer. Please only add numbers. For example: 1">
          <i class="fas fa-circle-info"></i>
        </span>
        <input formControlName="scheduleBufferDays" class="input" type="text" placeholder="Minimum Approval Days" (change)="changeForm()">
        <span style="color:red" *ngIf="(
              frmCreateSchedule.get('scheduleBufferDays').hasError('pattern') &&
              frmCreateSchedule.get('scheduleBufferDays').touched)">Invalid input.</span>
        </div>
    </div>
<div class="field mw-75 max-vw-100">
  <label class="label">Max. Possible Bookings</label>
  <div class="inner-tooltip-icon right-tooltip-addon">
    <span class="icon-tooltip has-tooltip-multiline" data-tooltip="The total number of bookings you want per each slot. For example: in case of Yoga class, if you would like 5 people to attend one session. You could add 5 here where there will be Max 5 people booking for a particular slot.">
      <i class="fas fa-circle-info"></i>
    </span>
    <input formControlName="scheduleTotalCount" class="input" type="text" placeholder="Total count" (change)="changeForm()">
    <span style="color:red" *ngIf="(
              frmCreateSchedule.get('scheduleTotalCount').hasError('pattern') &&
              frmCreateSchedule.get('scheduleTotalCount').touched)">Invalid input.</span>
    </div>
</div>
</div>
</div>
    <div class="field mw-100 max-vw-100">
      <label class="label">Guidelines</label>
      <p class="control is-expanded has-icons-right">
        <textarea formControlName="scheduleGuidelines" class="input" type="text" placeholder="Additional Schedule Guidelines / Contractual information" (change)="changeForm()"></textarea>
      </p>
    </div>

    <div class="border">
      <label class="label p-2">Cancellation policy</label>
      <div class="field is-horizontal">
        <div class="field-body p-2">
          <div class="field mw-25 max-vw-100">
            <label class="label">Full refund days</label>
            <div class="inner-tooltip-icon right-tooltip-addon">
              <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please add number of days so that user have an option to cancel the Offer on time and get a full refund. The actual date for full refund threshold will be calculated from the date of actual schedule minus the added days here.">
                <i class="fas fa-circle-info"></i>
              </span>
              <input formControlName="scheduleCanFullRefund" class="input" type="text" placeholder="Full refund" (change)="changeForm()">
              <span style="color:red" *ngIf="(
              frmCreateSchedule.get('scheduleCanFullRefund').hasError('pattern') &&
              frmCreateSchedule.get('scheduleCanFullRefund').touched)">Invalid input.</span>
              </div>
          </div>
      <div class="field mw-75 max-vw-100">
        <label class="label">Half refund days</label>
        <div class="inner-tooltip-icon right-tooltip-addon">
          <span class="icon-tooltip has-tooltip-multiline" data-tooltip="Please add number of days so that user have an option to cancel the Offer on time and get a half refund. Half refund days should be less than full refund days. The actual date for half refund threshold will be calculated from the date of actual schedule minus the added days here.">
            <i class="fas fa-circle-info"></i>
          </span>
          <input formControlName="scheduleCanHalfRefund" class="input" type="text" placeholder="Half refund" (change)="changeForm()">
          <span style="color:red" *ngIf="(
              frmCreateSchedule.get('scheduleCanHalfRefund').hasError('pattern') &&
              frmCreateSchedule.get('scheduleCanHalfRefund').touched)">Invalid input.</span>
          </div>
      </div>
      </div>
      </div>
    </div>
    <footer class="my-footer p-2">
      <div class="buttons">
        <div class="p-2" *ngIf="updateScheduleFlag === false">
        <button [disabled]="invalidSchedule" class="button is-outline is-primary is-create" (click)="upsertSchedule()">
          <span> Create</span>
        </button>
      </div>
      <div class="p-2" *ngIf="updateScheduleFlag === true">
        <button [disabled]="invalidSchedule" class="button is-outline is-primary is-create" (click)="upsertSchedule()">
          <span> Update</span>
        </button>
      </div>
        <button [disabled]="scheduleDisableNext" class="button is-outlined is-primary" (click)="callParent()">
          <span> Next</span>
          <span class="icon medium">
            <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
          </span>
        </button>
      </div>
    </footer>
    </div>
  </form>

  <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
    <span class="custom-day-template" [class.focused]="focused" [class.disabled]="isDisabled(date)" 
          [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
      {{ date.day }}
    </span>
  </ng-template>

  <ng-template #scheduleCustomDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
    <span class="custom-day-template" [class.focused]="focused" [class.disabled]="scheduleIsDisabled(date)" 
          [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
      {{ date.day }}
    </span>
  </ng-template>