<app-page-loader></app-page-loader>
<app-header [loginCheck]=true></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true></app-header-mobile>

<ng-template #contentProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="p-2">
      <img class="cropimageprofile" id="profileImage" #image [src]="profileImageLink" crossorigin />
    </div>
  </div>
</ng-template>

<!-- Profile Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Profile" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Profile Page" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="media">
          <a (click)="open(contentProfile)"><img [src]="profileImageLink" alt="avatar" /></a>
          <app-image-upload [isProfileImage]=true></app-image-upload>
          <div class="media-body">
            <h5 class="mt-0">{{ first_name }}&nbsp;{{ last_name }}</h5>
            <p>
              {{ "E-mail" | translate }}: <a href="#">{{ user_email }}</a>  
            </p>
            <p *ngIf="referral_code_module === 'True'">
              <ng-template #publicShareLinkContent let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Public Link</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <a [routerLink]="'/signup/'+referral_code">{{"https://zigwik.com/signup/" + referral_code}}</a>
                </div>
              </ng-template>
              {{ "Referral Code" | translate }}: {{ referral_code }}&nbsp;&nbsp;&nbsp;<a (click)="open(publicShareLinkContent)"><i class="fas fa-share icon fa-lg has-text-info"></i></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-user-sidebar [inputUserId]="hashed_user_id"></app-user-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <div class="profileform">
          <form [formGroup]="getUserForm">
        <div class="container">
              <legend>User Details</legend>
              <div class="field is-horizontal form-group">
                <div class="field-body pt-2">
                  <div class="field mw-50 max-vw-100">
                    <label class="label">First Name</label>
                    <input formControlName="firstname" class="form-control" type="text"
                      [ngClass]="{'is-invalid': submitted && f.firstname.errors }" required />
                    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                      <div *ngIf="f.firstname.errors.required">First Name is required</div>
                    </div>
                  </div>
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Last Name</label>
                    <input formControlName="lastname" class="form-control" type="text" placeholder="Last Name"
                      [ngClass]="{'is-invalid': submitted && f.lastname.errors }" required />
                    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                      <div *ngIf="submitted && f.lastname.errors.required">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal form-group">
                <div class="field-body pt-2">
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Birth Date</label>
                    <input *ngIf="(birthDateReadOnly | async) == false"
                      type="date"
                      name="birthdate"
                      class="form-control"
                      formControlName="birthdate">
                    <input *ngIf="(birthDateReadOnly | async) == true" formControlName="birthdatereadonlytext" class="form-control" type="text"
                    [readonly]="(birthDateReadOnly | async)" />                    
                  </div>
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Email</label>
                    <input formControlName="email" class="form-control" type="text" placeholder="Email"
                      readonly="readonly" />
                  </div>
                </div>
              </div>
              <div class="field is-horizontal form-group">
                <div class="field-body">
                  
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Contact Number</label>
                    <input formControlName="contact" class="form-control" type="text" placeholder="Contact Number"
                    [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"/>
                  </div>
                </div>
              </div>
              <div class="field  w-100">
                <label class="label">Automatic AI Profile Description</label>
              </div>
              <div class="field w-100">
                <div class="control has-icons-right">
                    <textarea formControlName="offerAiPrompt" name="offerAiPrompt" id="offerAiPrompt" class="input" type="text" placeholder="Write your biodata here for AI to create your profile." maxlength="2000"></textarea>
                </div>
              </div>
              <div class="field  w-100">
                <button id="aiGenerateDescriptionButton" type="button" class="button is-outline is-info is-small" (click)="generateAIDescription()">
                  <span> Generate</span>
                  <span class="icon medium">
                    <fa-icon [icon]="faChevronRight" size="fa-lg"> </fa-icon>
                  </span>
                </button>
              </div>
              <div class="field is-horizontal form-group">
                <div class="field-body">
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Description</label>
                    <div class="control has-icons-right">
                      <app-markdown-text-editor [inputValue]="profileInputDescription" (newItemHtmlValue)="addHtmlDescription($event)" (newItemMarkdownValue)="addMarkdownDescription($event)"></app-markdown-text-editor>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal form-group">
                <div class="field-body">
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Adhaar Card</label>
                    <div class="control has-icons-right">
                      <input formControlName="adhaarcard" class="form-control" type="text" placeholder="Adhaar Card"
                        [ngClass]="{ 'is-invalid': submitted && f.adhaarcard.errors }" />
                    </div>
                  </div>
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Pan Card</label>
                    <!-- <div class="control has-icons-right"> -->
                    <input formControlName="pancard" class="form-control mw-50 max-vw-100" type="text" placeholder="Pan Card"
                      [ngClass]="{ 'is-invalid': submitted && f.pancard.errors }" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container mt-4">
              <legend>Bank Details</legend>
              <div class="field is-horizontal form-group">
                <div class="field-body pt-2">
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Account Number</label>
                    <p class="control is-expanded has-icons-right"> </p>
                    <input formControlName="accountnumber" class="form-control" type="text" placeholder="Account Number"
                      [ngClass]="{ 'is-invalid': submitted && f.accountnumber.errors }" />
                  </div>
                  <div class="field mw-50 max-vw-100">
                    <label class="label">Bank Code</label>
                    <p class="control is-expanded has-icons-right"> </p>
                    <input formControlName="bankcode" class="form-control" type="text" placeholder="Bank Code"
                      [ngClass]="{ 'is-invalid': submitted && f.bankcode.errors }" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container mt-4">
              <legend>Location Details</legend>
              <div class="field-body pt-2 form-group">
                <div class="field w-1000">
                  <label class="label">Search Area</label>
                  <p class="control has-icons-right">
                  <input id="searchArea" class="input form-control" type="text" name="searchArea" placeholder="Search Area..." (keyup)="onKeyupAddress($event)" #searchArea>
                  <span class="icon is-small is-right">
                    <i class="fas fa-search"></i>
                  </span>
                </p>
                </div>
              </div>
              <div class="field-body pt-2 form-group">
                <div class="field mw-50 max-vw-100">
                  <label class="label">Address One</label>
                  <p class="control is-expanded has-icons-right"> </p>
                  <input id="addressone" formControlName="addressone" class="form-control" type="text" placeholder="Address One"
                    [ngClass]="{ 'is-invalid': submitted && f.addressone.errors }">
                  <div *ngIf="submitted && f.addressone.errors" class="invalid-feedback">
                    <div *ngIf="submitted && f.addressone.errors.required">
                      Address One is required
                    </div>
                  </div>
                </div>
                <div class="field mw-50 max-vw-100">
                  <label class="label">Address Two</label>
                  <p class="control is-expanded has-icons-right"> </p>
                  <input id="addresstwo" formControlName="addresstwo" class="form-control" type="text" placeholder="Address Two">
                </div>
              </div>
              <div class="field-body pt-2 form-group">
                <div class="field mw-50 max-vw-100">
                  <label class="label">Landmark</label>
                  <p class="control is-expanded has-icons-right"> </p>
                  <input id="landmark" formControlName="landmark" class="form-control" type="text" placeholder="Landmark"
                    value="pincode" />
                </div>
                <div class="field mw-50 max-vw-100">
                  <label class="label">Pin Code</label>
                  <p class="control is-expanded has-icons-right"></p>
                  <input id="pincode" formControlName="pincode" class="form-control" type="text" placeholder="Pin Code"
                    [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" />
                  <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                    <div *ngIf="submitted && f.pincode.errors.required">
                      Pin Code is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-body pt-2 form-group">
                <div class="field mw-50 max-vw-100">
                  <label class="label">Area Name</label>
                    <input id="area" formControlName="area" class="form-control" type="text" placeholder="Area" />
                </div>
                <div class="field mw-50 max-vw-100">
                  <label class="label">City</label>
                  <input id="city" formControlName="city" class="form-control" type="text" placeholder="City" />
                </div>
              </div>
              <div class="field-body pt-2 form-group">
                <div class="field mw-50 max-vw-100">
                  <label class="label">District</label>
                  <input id="district" formControlName="district" class="form-control" type="text" placeholder="District" />
                </div>
                <div class="field mw-50 max-vw-100">
                  <label class="label">State</label>
                    <input id="state" formControlName="state" class="form-control" type="text" placeholder="State">
                </div>
              </div>
              <footer class="my-footer">
                <div class="from-group">
                  <div class="buttons pt-5">
                    <button id="profileUpdateButton" type="button" class="btn btn-secondary swipe-to-top" (click)="updateProfile()">
                      <span> Update</span>
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<!-- <app-accept-cookie></app-accept-cookie> -->
<app-back-to-top></app-back-to-top>
<!-- <app-switcher></app-switcher> -->