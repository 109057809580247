<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="my-offering"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="my-offering"></app-header-mobile>

<!-- Profile Content -->

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "My Offering" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-offerings-sidebar></app-offerings-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="width:20%;" scope="col">Category</th>
              <th style="width:30%;" scope="col">Title</th>
              <th style="width:20%;" scope="col">Offer Name</th>
              <th style="width:10%;" scope="col">Public Link</th>
              <th style="width:10%;" scope="col">Version</th>
              <th style="width:10%;" scope="col">Hide / Show in Search</th>
              <th style="width:10%;" scope="col">Edit / Activate</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productList">
              <td>{{product["category"]}}</td>
              <td>
                  <a [routerLink]="'/offer/'+product['id']">{{product["title"]}}</a>
              </td>
              <td>
                {{product["product_name"]}}
            </td>
            <td>
              <ng-template #publicLinkContent let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Public Link</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <a [routerLink]="'/offername/'+product['product_name']">{{loc + "/offername/" + product['product_name']}}</a>
                </div>
              </ng-template>
              <a *ngIf="product['product_name'] != null && product['product_name'] != ''" (click)="openModal(publicLinkContent)"><i class="fas fa-info-circle text-secondary"></i></a>
            </td>
              <td>{{product["version"]}}</td>
              <td><a title="Hide the Offer. Once you do that, it won't show up in the search results. However, the Offer is still active and you can use the public link to share it with others for booking." *ngIf="product['hide_from_search'] === false || product['hide_from_search'] === null" (click)="updateProduct(product['id'], product['hide_from_search'])"><i class="fas fa-toggle-on product-icon-active"></i></a><span *ngIf="product['hide_from_search'] === false || product['hide_from_search'] === null">&nbsp;</span><a title="Show the Offer so that it can be searched by other users." *ngIf="product['hide_from_search'] === true" (click)="updateProduct(product['id'], product['hide_from_search'])"><i class="fas fa-toggle-off product-icon-passive"></i></a></td>
              <td><a title="Edit the Offer. Please note that if you edit the description or the guidelines, it will actually create another version of the Offer for you as we have to keep the pervious version in case there are any bookings done." [routerLink]="'/update-offering/'+product['id']"><i class="fas fa-edit product-icon-passive"></i></a>&nbsp;<a title="Deactivate the Offer. Once you do that, it won't show up in the search results. Also, please note that if there are no schedules active, it won't show up." *ngIf="product['status'] == 1" (click)="toggleProductStatus(product['id'], product['status'])"><i class="fas fa-toggle-on product-icon-active"></i></a><span *ngIf="product['status'] == 1">&nbsp;</span><a title="Activate the Offer so that it can be searched by other users. Please note that in order to activate the Offer, you need a valid schedule created." *ngIf="product['status'] == 0" (click)="toggleProductStatus(product['id'], product['status'])"><i class="fas fa-toggle-off product-icon-passive"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
