import { Component, OnInit, Input, ViewChild, Directive, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControlName } from "@angular/forms";
import { GetCategoryQuery } from "src/app/services/graphql/getcategory.graphql";
import { GetDayTemplatesQuery } from "src/app/services/graphql/getDayTemplates.graphql";
import { GetWeekTemplatesQuery } from "src/app/services/graphql/getWeekTemplates.graphql";
import { GetProductAttributesQuery } from "src/app/services/graphql/getProductAttributes.graphql";
import { CreateDayTemplateMutation } from "src/app/services/graphql/createDayTemplate.graphql";
import { CreateWeekTemplateMutation } from "src/app/services/graphql/createWeekTemplate.graphql";
import { CreateAttributeMutation } from "src/app/services/graphql/createAttribute.graphql";
import { DeleteDayTemplateMutation } from "src/app/services/graphql/deleteDayTemplate.graphql";
import { DeleteWeekTemplateMutation } from "src/app/services/graphql/deleteWeekTemplate.graphql";
import { HasProductSchedulesQuery } from "src/app/services/graphql/hasProductSchedules.graphql";
import { DeleteOfferAttributeMutation } from "src/app/services/graphql/deleteOfferAttribute.graphql";
import { GenerateAIDescriptionMutation } from "src/app/services/graphql/generateAIDescription.graphql";
import { ReplaceAITextMutation } from "src/app/services/graphql/replaceAIText.graphql";
import { ValidateAITextMutation } from "src/app/services/graphql/validateAIText.graphql";

import { Subscription } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "../../../constants/app.constants";
import { Apollo } from "apollo-angular";
import { CreateProductMutation } from "../../../services/graphql/createproduct.graphql";
import { UpdateProductMutation } from "../../../services/graphql/updateproduct.graphql";
import { GetAreaQuery } from "../../../services/graphql/getarea.graphql";
import { DaySchedulerComponent } from "../../../components/day-scheduler/day-scheduler.component";
import { ActivateProductComponent } from "../../../components/activate-product/activate-product.component";
import { AddSchedulesComponent } from "../../../components/add-schedules/add-schedules.component";
import { MarkdownTextEditorComponent } from "../../../components/markdown-text-editor/markdown-text-editor.component";
import { ToastrService } from 'ngx-toastr';
import { GetProductDetailsQuery } from 'src/app/services/graphql/getProductDetails.graphql';

import {
  faUser,
  faAddressBook,
  faCreditCard,
  faChevronLeft,
  faChevronRight,
  faSave,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { SessionService } from "src/app/services/session.service";
import { NullTemplateVisitor } from "@angular/compiler";
import { CdkStepper } from "@angular/cdk/stepper";
import { count } from "console";


interface checkedSlot {
  start_time: string;
  end_time: string;
}

@Component({
  selector: "app-new-offerings",
  templateUrl: "./new-offerings.component.html",
  styleUrls: ["./new-offerings.component.scss"],
})
export class NewOfferingsComponent implements OnInit {
  @ViewChild(DaySchedulerComponent, { static: true })
  daySchedulerComponent: DaySchedulerComponent;

  @ViewChild(ActivateProductComponent, { static: true })
  activateProductComponent: ActivateProductComponent;

  @ViewChild(AddSchedulesComponent, {static: true})
  addSchedulesComponent: AddSchedulesComponent;

  @Input()
  isLinear = true;

  @Input()
  isEditable = true;

  @ViewChild('cdkStepper')
  cdkStepper: CdkStepper;

  showAddress = false;
  disableNext = true;
  offerHtmlDescription = "";
  offerMarkdownDescription = "";
  offerHtmlGuidelines = "";
  offerMarkdownGuidelines = "";
  hideFromSearchFlag = false;
  hideFromSearch = new BehaviorSubject<boolean>(
    false
  );

  offerInputDescription = new BehaviorSubject<string>(
    ""
  );

  offerInputGuidelines = new BehaviorSubject<string>(
    ""
  );

  subscription: Subscription;
  areaSubscription: Subscription;
  deleteDaySubscription: Subscription;
  deleteWeekSubscription: Subscription;
  productID = 0;
  categoryID = new BehaviorSubject<number>(
    0
  );
  dayTemplateID = 0;
  weekTemplateID = 0;
  productTitle = "";
  productDayTemplates: any;
  productWeekTemplates: any;
  dayTemplateValidCheck = true;
  weekTemplateValidCheck = true;
  daySchedulerSetFlag = false;
  checkedSlots = [];
  // disableTemplateNext = true;

  disableTemplateNext = new BehaviorSubject<boolean>(
    true
  );


  disableScheduleNextSync = true;

  disableScheduleNext = new BehaviorSubject<boolean>(
    true
  );

  noAttributes = true;
  selectedAttributes = [];
  updateDayTemplateFlag = false;
  updateWeekTemplateFlag = false;
  hasSchedules = false;
  updateScheduleId = new BehaviorSubject<number>(
    0
  );
  deletedDayTemplateFlag = new BehaviorSubject<string>(
    "NA"
  );
  checkedSlotsForDisplay = new BehaviorSubject<Array<checkedSlot>>(
    []
  );
  checkedSlotCount = new BehaviorSubject<number>(
    0
  );
  deletedWeekTemplateFlag = new BehaviorSubject<string>(
    "NA"
  );
  productDetailsData = new BehaviorSubject<any>(
    null
  );
  productCreated = new BehaviorSubject<boolean>(
    false
  );
  addDayTemplateOnUpdate = new BehaviorSubject<boolean>(
    false
  );
  addWeekTemplateOnUpdate = new BehaviorSubject<boolean>(
    false
  );
  newProductID = new BehaviorSubject<number> (
    0
  );
  updateButtonFlag = true;
  updateFlag = false;
  weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


  categoryData = [];

  // get filered categories after group category changed
  filterCategories = [];
  filterMajorCategories = [];
  filterMinorCategories = [];

  durationHrs = Array(24)
    .fill(0)
    .map((x, i) => i);
  durationMins = Array(4)
    .fill(1)
    .map((x, i) => i * 15);

  faPerson = faUser;
  faAddressBook = faAddressBook;
  faCreditCard = faCreditCard;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSave = faSave;

  faBan = faBan;

  // Area Data
  areaName = [];
  city = [];
  district = [];
  country = [];

  constructor(
    private fb: FormBuilder,
    private getCategoryQuery: GetCategoryQuery,
    private apollo: Apollo,
    private createProductMutation: CreateProductMutation,
    private sessionService: SessionService,
    private getAreaQuery: GetAreaQuery,
    private getDayTemplatesQuery: GetDayTemplatesQuery,
    private getWeekTemplatesQuery: GetWeekTemplatesQuery,
    private getProductAttributesQuery: GetProductAttributesQuery,
    private hasProductSchedulesQuery: HasProductSchedulesQuery,
    private createDayTemplateMutation: CreateDayTemplateMutation,
    private createWeekTemplateMutation: CreateWeekTemplateMutation,
    private createAttributeMutation: CreateAttributeMutation,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private routeWeb: Router,
    private getProductDetailsQuery: GetProductDetailsQuery,
    private updateProductMutation: UpdateProductMutation,
    private deleteDayTemplateMutation: DeleteDayTemplateMutation,
    private deleteWeekTemplateMutation: DeleteWeekTemplateMutation,
    private deleteOfferAttributeMutation: DeleteOfferAttributeMutation,
    private generateAIDescriptionMutation: GenerateAIDescriptionMutation,
    private replaceAITextMutation: ReplaceAITextMutation,
    private validateAITextMutation: ValidateAITextMutation
    ) {
    this.routeWeb.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  };
  }

  nextStep() {
    window.scrollTo(0, 0);
    this.cdkStepper.next();
  }

  parentStepper(step: number) {
    this.activateProductComponent.querySchedules();

    this.activateProductComponent.scheduleAvailable.subscribe(
      (data) => {
        this.disableScheduleNext.next(!data);
        this.disableScheduleNextSync = !data
        if(step == 3 && this.disableScheduleNextSync === false) {
          window.scrollTo(0, 0);
          this.cdkStepper.next();
        }    
      }
    );
  }

  get f() {
    return this.frmProductDetails.controls;
  }

  parentLoader() {
    this.activateProductComponent.querySchedules();

    this.activateProductComponent.scheduleAvailable.subscribe(
      (data) => {
        this.disableScheduleNext.next(!data);
        this.disableScheduleNextSync = !data;
      }
    );
  }

  exportedInterfaces(): NewOfferingsComponentInterface {
    return {
      addDayScheduleSlot: (setFlag, checkedSlots) => {
        this.addDaySlotInDailyScheduler(setFlag, checkedSlots);
      },

      updateSchedule: (scheduleId) => {
        this.updateScheduleId.next(scheduleId);
        this.cdkStepper.previous();

      }
    };
  }

  addHtmlDescription(value: string) {
    this.offerHtmlDescription = value;
  }

  addMarkdownDescription(value: string) {
    this.offerMarkdownDescription = value;
  }

  addHtmlGuidelines(value: string) {
    this.offerHtmlGuidelines = value;
  }

  addMarkdownGuidelines(value: string) {
    this.offerMarkdownGuidelines = value;
  }

  addDaySlotInDailyScheduler(setFlag, checkedSlots) {
    if(setFlag == "true") {
      this.daySchedulerSetFlag = true;
    } else {
      this.daySchedulerSetFlag = false;
    }

    if(this.frmScheduleTemplates.invalid == false && setFlag == "true") {
      this.dayTemplateValidCheck = false;
    } else {
      this.dayTemplateValidCheck = true;
    }
    this.checkedSlots = checkedSlots;

    var hours = this.frmScheduleTemplates.controls.durationHrs.value;
    var minutes = this.frmScheduleTemplates.controls.durationMin.value;

    if(hours == null || hours == "") {
      hours = "00";
    }

    if(hours.length <= 1) {
      hours = "0" + hours;
    }

    if(minutes == null || minutes == null) {
      minutes = "00";
    }

    if(minutes.length <= 1) {
      minutes = "0" + minutes;
    }
    
    var slot_array = [];
    checkedSlots.forEach(slot => {

      var start_hour = slot.start_hour.toString();
      var start_minute = slot.start_minute.toString();

      if(start_hour.length <= 1) {
        start_hour = "0" + start_hour;
      }

      if(start_minute.length <= 1) {
        start_minute = "0" + start_minute;
      }

      var temp_date = new Date("2022-01-01 " + start_hour + ":" + start_minute + ":" + "00");

      temp_date.setHours(temp_date.getHours() + parseInt(hours));
      temp_date.setMinutes(temp_date.getMinutes() + parseInt(minutes));

      var end_hour = temp_date.getHours().toString();
      var end_minute = temp_date.getMinutes().toString();

      if(end_hour.length <= 1) {
        end_hour = "0" + end_hour;
      }

      if(end_minute.length <= 1) {
        end_minute = "0" + end_minute;
      }

      slot_array.push({
        start_time: start_hour + ":" + start_minute,
        end_time: end_hour + ":" + end_minute
      });
    });

    this.checkedSlotCount.next(slot_array.length);
    this.checkedSlotsForDisplay.next(slot_array);
  }

  loadDayTemplate(dayTemplateId: number, templateName: string, slots: Array<any>) {
    this.frmScheduleTemplates.patchValue({
      templateName: templateName,
      durationHrs: slots[0]["slot_hours"],
      durationMin: slots[0]["slot_minutes"]});

      this.dayTemplateID = dayTemplateId;

      this.daySchedulerComponent.hours = slots[0]["slot_hours"];
      this.daySchedulerComponent.mins = slots[0]["slot_minutes"];

      let slotsVal = [];

      slots.forEach(slot => {
        slotsVal.push({start_hour: slot["start_hour"], start_minute: slot["start_minute"]});
      });

      this.daySchedulerComponent.checkedSlots = slotsVal;

      this.daySchedulerComponent.fillupdateSlots();

      this.updateDayTemplateFlag = true;

  }

  loadWeekTemplate(weekTemplateId: number,
    templateName: string,
    monTemplateId: number,
    tueTemplateId: number,
    wedTemplateId: number,
    thurTemplateId: number,
    friTemplateId: number,
    satTemplateId: number,
    sunTemplateId: number) {

      let templates = [];
      this.productDayTemplates.forEach(dayTemplate => {
        templates[dayTemplate["day_template_id"]] = dayTemplate["template_name"];
      });

      this.weekTemplateID = weekTemplateId;

      this.frmScheduleWeekTemplates.patchValue({
        templateName: templateName,
        mondayTemplate: templates[monTemplateId],
        tuesdayTemplate: templates[tueTemplateId],
        wednesdayTemplate: templates[wedTemplateId],
        thursdayTemplate: templates[thurTemplateId],
        fridayTemplate: templates[friTemplateId],
        saturdayTemplate: templates[satTemplateId],
        sundayTemplate: templates[sunTemplateId]
        });

        this.updateWeekTemplateFlag = true;

        this.checkWeekTemplateForm();
  }

  frmProductDetails = this.fb.group({
    categoryGroup: ["", Validators.compose([Validators.required])],
    category: ["", Validators.compose([Validators.required])],
    majorCategory: [""],
    minorCategory: [""],
    title: ["", Validators.compose([Validators.required])],
    productName: ["", Validators.compose([Validators.required, Validators.pattern('[a-z0-9]*')])],
offerAiPrompt: [""],
    address1: [""],
    address2: [""],
    area: [""],
    landmark: [""],
    pincode: [""],
    city: [""],
    district: [""],
    state: [""],
    productPropertyName: [""],
    productPropertyValue: [""],
    categoryGroupText: [""],
    categoryText: [""],
    subCategory1Text: [""],
    subCategory2Text: [""],
    license: [""],
    hideFromSearchTag: [false],
  });

  frmScheduleTemplates = this.fb.group({
    templateName: ["Day Template " + (new Date).toLocaleString(), Validators.compose([Validators.required])],
    durationHrs: [Validators.required],
    durationMin: [Validators.required]
  });

  frmScheduleWeekTemplates = this.fb.group({
    templateName: ["Week Template " + (new Date).toLocaleString(), Validators.compose([Validators.required])],
    mondayTemplate: [],
    tuesdayTemplate: [],
    wednesdayTemplate: [],
    thursdayTemplate: [],
    fridayTemplate: [],
    saturdayTemplate: [],
    sundayTemplate: []
  });

  ngOnInit() {

    this.route.paramMap.subscribe(
      params => {
        if(params.get('id') != null && params.get('id') != "") {
          this.productID = +params.get('id');
        }
      }
    );

    this.frmScheduleTemplates.get("templateName").valueChanges.subscribe(x => {
      this.dayTemplateValidCheck = true;
      this.daySchedulerComponent.clearCalendar();
    })

    this.frmScheduleWeekTemplates.get("templateName").valueChanges.subscribe(x => {
      this.weekTemplateValidCheck = true;
      this.frmScheduleWeekTemplates.controls.mondayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.tuesdayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.wednesdayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.thursdayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.fridayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.saturdayTemplate.reset();
      this.frmScheduleWeekTemplates.controls.sundayTemplate.reset();
    })

    this.apollo
      .query({
        query: this.getCategoryQuery.document,
        variables: {
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.getCategoryQuery.populateCategoryAndGroup(data);
          this.categoryData = this.getCategoryQuery.categoryGroup;
        },
        (error) => {
          console.log(error);
        }
      );

    this.areaSubscription =
      this.getAreaQuery._areaDataLoadedObservble.subscribe((data) => {
        if (data === EventConstants.CONST_AREA_LOADED) {
          this.areaName = this.getAreaQuery.areaName;
          this.city = this.getAreaQuery.city;
          this.district = this.getAreaQuery.district;
          this.country = this.getAreaQuery.country;
        }
      });

      this.queryDayTemplate();
      this.queryWeekTemplate();

      if((this.productWeekTemplates != null && this.productWeekTemplates.length > 0) || 
          (this.productDayTemplates != null && this.productDayTemplates.length > 0)) {
            this.disableTemplateNext.next(false);
          } else {
            this.disableTemplateNext.next(true);
          }

    this.activateProductComponent.querySchedules();

    this.activateProductComponent.scheduleAvailable.subscribe(
      (data) => {
        this.disableScheduleNext.next(!data);
        this.disableScheduleNextSync = !data;
      }
    );

    if(this.productID != null && this.productID != 0) {
      this.updateFlag = true;
      this.isLinear = false;
      this.disableNext = false;

      this.productDetail();
      this.hasProductSchedules();

      this.productDetailsData.subscribe(
        (data) => {
          if(data != null) {
            this.categoryID.next(data["category_id"]);
            this.frmProductDetails.patchValue({
              title: data["title"],
              productName: data["product_name"],
              categoryGroupText: data["category_group"],
              categoryText: data["category"],
              subCategory1Text: data["sub_category1"],
              subCategory2Text: data["sub_category2"],
              guidelines: data["guidelines"]});
            this.frmProductDetails.controls.title.disable();
            this.frmProductDetails.controls.productName.disable();
            this.frmProductDetails.controls.categoryGroupText.disable();
            this.frmProductDetails.controls.categoryText.disable();
            this.frmProductDetails.controls.subCategory1Text.disable();
            this.frmProductDetails.controls.subCategory2Text.disable();
          }
        }
      );

      this.productAttributes();
    }
  }

  updateButton() {
    if(this.frmProductDetails.controls.license.value == null || this.frmProductDetails.controls.license.value == false) {
      this.updateButtonFlag = false;
    } else {
      this.updateButtonFlag = true;
    }
  }

  updateTemplates() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let dayTemplateCnt = 0;
    let weekTemplateCnt = 0;

    let dayTemplatesNew = [];

    if(this.productDayTemplates != null && this.productDayTemplates.length > 0) {
      this.productDayTemplates.forEach(dayTemplate => {
        let slot = [];
        dayTemplate["slots"].forEach(
          (templateSlot) => {
            slot.push({start_hour: templateSlot["start_hour"],
          start_minute: templateSlot["start_minute"]});
          }
        );

        this.apollo
        .mutate({
          mutation: this.createDayTemplateMutation.document,
          variables: {
            template_name: dayTemplate["template_name"],
            product_id: this.productID,
            id_token: token,
            lender_email: email,
            slot_hours: dayTemplate["slots"][0]["slot_hours"],
            slot_minutes: dayTemplate["slots"][0]["slot_minutes"],
            slots: slot
          },
        })
        .subscribe(
          ({ data }) => {
            dayTemplateCnt++;

            dayTemplatesNew[dayTemplate["day_template_id"]] = data["addProductDayTemplate"];

            if(dayTemplateCnt == this.productDayTemplates.length) {
              this.addDayTemplateOnUpdate.next(true);
            }
          },
          (error) => {
            document.getElementById("updateProductButton").classList.remove("is-loading");
            this.toastr.error(error.message);
          }
        );
      });
    } else {
      this.addDayTemplateOnUpdate.next(true);
    }

    this.addDayTemplateOnUpdate.subscribe(
    (data) => {
      if(data == true) {
        if(this.productWeekTemplates != null && this.productWeekTemplates.length > 0) {
          this.productWeekTemplates.forEach(weekTemplate => {
            this.apollo
            .mutate({
              mutation: this.createWeekTemplateMutation.document,
              variables: {
                template_name: weekTemplate["template_name"],
                product_id: this.productID,
                id_token: token,
                lender_email: email,
                mon_day_template_id: dayTemplatesNew[weekTemplate["mon_day_template_id"]],
                tue_day_template_id: dayTemplatesNew[weekTemplate["tue_day_template_id"]],
                wed_day_template_id: dayTemplatesNew[weekTemplate["wed_day_template_id"]],
                thur_day_template_id: dayTemplatesNew[weekTemplate["thur_day_template_id"]],
                fri_day_template_id: dayTemplatesNew[weekTemplate["fri_day_template_id"]],
                sat_day_template_id: dayTemplatesNew[weekTemplate["sat_day_template_id"]],
                sun_day_template_id: dayTemplatesNew[weekTemplate["sun_day_template_id"]]
              },
            })
            .subscribe(
              ({ data }) => {
                weekTemplateCnt++;
                if(weekTemplateCnt == this.productWeekTemplates.length) {
                  this.addWeekTemplateOnUpdate.next(true);
                }
              },
              (error) => {
                document.getElementById("updateProductButton").classList.remove("is-loading");
                this.toastr.error(error.message);
              }
            );
          });
        } else {
          this.addWeekTemplateOnUpdate.next(true);
        }
      }
    }
    )
    
  }

  productAttributes() {
    this.apollo
      .query({
        query: this.getProductAttributesQuery.document,
        variables: {
          product_id: this.productID
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductAttributes"].length > 0) {
            this.selectedAttributes = [];
            data["getProductAttributes"].forEach(attribute => {
              this.selectedAttributes.push({attribute_key: attribute["attribute_key"],
              attribute_value: attribute["attribute_value"]});
            });

            this.noAttributes = false;
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  hasProductSchedules() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .query({
        query: this.hasProductSchedulesQuery.document,
        variables: {
          productId: this.productID,
          idToken: token,
          lenderEmail: email
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["hasProductSchedules"] != null && data["hasProductSchedules"] != "" && data["hasProductSchedules"] == "true") {
            this.hasSchedules = true;
            this.updateButtonFlag = true;
          } else {
            this.hasSchedules = false;
            this.updateButtonFlag = false;
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  productDetail() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .query({
        query: this.getProductDetailsQuery.document,
        variables: {
          product_id: this.productID,
          id_token: token,
          lender_email: email
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductDetails"]) {
            this.productDetailsData.next(data["getProductDetails"]);
            this.offerInputDescription.next(data["getProductDetails"]["product_markdown_description"]);
            this.offerInputGuidelines.next(data["getProductDetails"]["markdown_guidelines"]);
            this.hideFromSearch.next(data["getProductDetails"]["hide_from_search"]);
            this.hideFromSearchFlag = data["getProductDetails"]["hide_from_search"];
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  addAttributesTable() {
    if(this.frmProductDetails.controls.productPropertyName.value == null || 
      this.frmProductDetails.controls.productPropertyName.value == "") {
      this.toastr.error("The property name is blank.");
      return null;
    }

    if(this.frmProductDetails.controls.productPropertyValue.value == null || 
      this.frmProductDetails.controls.productPropertyValue.value == "") {
      this.toastr.error("The property value is blank.");
      return null;
    }

    let existsFlag = "false";

    this.selectedAttributes.forEach(attribute => {
      if(this.frmProductDetails.controls.productPropertyName.value == attribute["attribute_key"]) {
        this.toastr.error("The same attribute key already exists.");
        existsFlag = "true";
      }
    });

    if(existsFlag == "true") {
      return null;
    }

    this.selectedAttributes.push({attribute_key: this.frmProductDetails.controls.productPropertyName.value,
      attribute_value: this.frmProductDetails.controls.productPropertyValue.value});

    this.noAttributes = false;
  }

  deleteAttributeRef(selectedAttributeId) {
    this.selectedAttributes.forEach((attribute, index) => {
      if(attribute["attribute_key"] == selectedAttributeId) {
        this.selectedAttributes.splice(index, 1);
      }
    });

    if(this.selectedAttributes == null || this.selectedAttributes.length <= 0) {
      this.noAttributes = true;
    }
  }

  checkWeekTemplateForm() {
    let cnt = 0;
    var validFlag = false;

    if(this.frmScheduleWeekTemplates.controls.mondayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.mondayTemplate.value != "" && 
      (document.getElementById("mondayTemplate") as HTMLSelectElement).options[(document.getElementById("mondayTemplate") as HTMLSelectElement).options.selectedIndex].id != "monday_0" && 
      (this.frmScheduleWeekTemplates.controls.mondayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.tuesdayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.tuesdayTemplate.value != "" && 
      (document.getElementById("tuesdayTemplate") as HTMLSelectElement).options[(document.getElementById("tuesdayTemplate") as HTMLSelectElement).options.selectedIndex].id != "tuesday_0" && 
      (this.frmScheduleWeekTemplates.controls.tuesdayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.wednesdayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.wednesdayTemplate.value != "" && 
      (document.getElementById("wednesdayTemplate") as HTMLSelectElement).options[(document.getElementById("wednesdayTemplate") as HTMLSelectElement).options.selectedIndex].id != "wednesday_0" && 
      (this.frmScheduleWeekTemplates.controls.wednesdayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.thursdayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.thursdayTemplate.value != "" && 
      (document.getElementById("thursdayTemplate") as HTMLSelectElement).options[(document.getElementById("thursdayTemplate") as HTMLSelectElement).options.selectedIndex].id != "thursday_0" && 
      (this.frmScheduleWeekTemplates.controls.thursdayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.fridayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.fridayTemplate.value != "" &&
      (document.getElementById("fridayTemplate") as HTMLSelectElement).options[(document.getElementById("fridayTemplate") as HTMLSelectElement).options.selectedIndex].id != "friday_0" && 
      (this.frmScheduleWeekTemplates.controls.fridayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.saturdayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.saturdayTemplate.value != "" && 
      (document.getElementById("saturdayTemplate") as HTMLSelectElement).options[(document.getElementById("saturdayTemplate") as HTMLSelectElement).options.selectedIndex].id != "saturday_0" && 
      (this.frmScheduleWeekTemplates.controls.saturdayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(this.frmScheduleWeekTemplates.controls.sundayTemplate.value != null && 
      this.frmScheduleWeekTemplates.controls.sundayTemplate.value != "" && 
      (document.getElementById("sundayTemplate") as HTMLSelectElement).options[(document.getElementById("sundayTemplate") as HTMLSelectElement).options.selectedIndex].id != "sunday_0" && 
      (this.frmScheduleWeekTemplates.controls.sundayTemplate.dirty == true || 
        this.updateWeekTemplateFlag === true)) {
      // cnt++;
      validFlag = true;
    }

    if(validFlag == true && this.frmScheduleWeekTemplates.invalid == false) {
      this.weekTemplateValidCheck = false;
    } else {
      this.weekTemplateValidCheck = true;
    }
  }

  queryWeekTemplate() {
    if(this.productID != null && this.productID != 0) {
      this.apollo
      .mutate({
        mutation: this.getWeekTemplatesQuery.document,
        variables: {
          productId: this.productID
          }
      })
      .subscribe(
        ({ data }) => {
          this.productWeekTemplates = data["getProductWeekTemplates"];
          if((this.productWeekTemplates != null && this.productWeekTemplates.length > 0) || 
          (this.productDayTemplates != null && this.productDayTemplates.length > 0)) {
            this.disableTemplateNext.next(false);
          } else {
            this.disableTemplateNext.next(true);
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

  queryDayTemplate() {
    if(this.productID != null && this.productID != 0) {
      this.apollo
      .mutate({
        mutation: this.getDayTemplatesQuery.document,
        variables: {
          productId: this.productID
          }
      })
      .subscribe(
        ({ data }) => {
          this.productDayTemplates = data["getProductDayTemplates"];
          if((this.productWeekTemplates != null && this.productWeekTemplates.length > 0) || 
          (this.productDayTemplates != null && this.productDayTemplates.length > 0)) {
            this.disableTemplateNext.next(false);
          } else {
            this.disableTemplateNext.next(true);
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

  handleCategoriesChange($evt) {

    this.getCategoryQuery.selectedCategory = null;
    this.getCategoryQuery.filteredCount = 0;
    let categoryGroup = this.frmProductDetails.value["categoryGroup"];
    let category = this.frmProductDetails.value["category"];
    let majorCategory = this.frmProductDetails.value["majorCategory"];
    let minorCategory = this.frmProductDetails.value["minorCategory"];

    this.showAddress = false;
    
    if ($evt.target.name === "categoryGroup") {
      this.filterCategories = [];
      this.filterMajorCategories = [];
      this.filterMinorCategories = [];
      this.filterCategories =
        this.getCategoryQuery.filterCategoryByGroupCategory(
          categoryGroup,
          "",
          ""
        );
    }
    if ($evt.target.name === "category") {
      this.filterMajorCategories = [];
      this.filterMinorCategories = [];
      this.filterMajorCategories =
        this.getCategoryQuery.filterCategoryByGroupCategory(
          categoryGroup,
          category,
          ""
        );
    }
    if ($evt.target.name === "majorCategory") {
      this.filterMinorCategories = [];
      this.filterMinorCategories =
        this.getCategoryQuery.filterCategoryByGroupCategory(
          categoryGroup,
          category,
          majorCategory
        );
    }
    if ($evt.target.name === "minorCategory") {
          this.getCategoryQuery.filterCategoryByGroupCategory(
          categoryGroup,
          category,
          majorCategory,
          minorCategory
        );
    }

    if(this.getCategoryQuery.filteredCount == 1) {
      if(this.getCategoryQuery.selectedCategory != null && (this.getCategoryQuery.selectedCategory.address_required == true || 
        this.getCategoryQuery.selectedCategory.address_required == "true")) {
          this.showAddress = true;
          this.frmProductDetails.controls['address1'].setValidators([Validators.required]);
          this.frmProductDetails.controls['area'].setValidators([Validators.required]);
          this.frmProductDetails.controls['pincode'].setValidators([Validators.required]);
          this.frmProductDetails.controls['city'].setValidators([Validators.required]);
          this.frmProductDetails.controls['district'].setValidators([Validators.required]);
          this.frmProductDetails.controls['state'].setValidators([Validators.required]);
        }
    } 
    else {
      this.frmProductDetails.controls['address1'].clearValidators();
      this.frmProductDetails.controls['area'].clearValidators();
      this.frmProductDetails.controls['pincode'].clearValidators();
      this.frmProductDetails.controls['city'].clearValidators();
      this.frmProductDetails.controls['district'].clearValidators();
      this.frmProductDetails.controls['state'].clearValidators();
    }

  }

  deleteDayTemplate(day_template_id: number = 0) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let dayTemplate = 0;

    if(day_template_id !== 0) {
      dayTemplate = day_template_id;
    } else {
      dayTemplate = this.dayTemplateID;
    }

    this.apollo
    .mutate({
      mutation: this.deleteDayTemplateMutation.document,
      variables: {
        template_id: dayTemplate,
        product_id: this.productID,
        id_token: token,
        lender_email: email,
      },
    })
    .subscribe(
      ({ data }) => {
        if(data["deleteProductDayTemplate"] != null) {
          if(this.updateDayTemplateFlag === true) {
            this.deletedDayTemplateFlag.next("update");  
          } else {
            this.deletedDayTemplateFlag.next("delete");
          }
          if(day_template_id !== 0) {
            this.queryDayTemplate(); 
          }
          this.toast("Day Template deleted successfully.");
        }
      },
      (error) => {
        this.toastr.error(error.message);
        this.deletedDayTemplateFlag.next("NA");
        if(this.updateDayTemplateFlag === true) {
          document.getElementById("dayTemplateUpdateButton").classList.remove("is-loading");
        } else {
          document.getElementById("dayTemplateCreationButton").classList.remove("is-loading");
        }
      }
    );
  }
  
  generateAIDescription(){

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    document.getElementById("aiGenerateDescriptionButton").classList.add("is-loading");

    var inputText = "";

    if(this.frmProductDetails.controls.offerAiPrompt.value != null && this.frmProductDetails.controls.offerAiPrompt.value != "") {
      inputText = this.frmProductDetails.controls.offerAiPrompt.value;
    } else if(this.frmProductDetails.controls.title.value != null && this.frmProductDetails.controls.title.value != "") {
      inputText = this.frmProductDetails.controls.title.value;
    }    

    this.apollo
    .mutate({
      mutation: this.generateAIDescriptionMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: inputText,
      },
    })
    .subscribe(
      ({ data }) => {
        this.offerInputDescription.next(data['generateAIDescription']);
        this.apollo
        .mutate({
          mutation: this.generateAIDescriptionMutation.document,
          variables: {
            offeror_email: email,
            id_token: token,
            input_text: inputText,
            guidelines_flag: true
          },
        })
        .subscribe(
          ({ data }) => {
            this.offerInputGuidelines.next(data['generateAIDescription']);
            document.getElementById("aiGenerateDescriptionButton").classList.remove("is-loading");
          },
          (error) => {
            this.toastr.error(error.message);
            document.getElementById("aiGenerateDescriptionButton").classList.remove("is-loading");
          }
        );
      },
      (error) => {
        this.toastr.error(error.message);
        document.getElementById("aiGenerateDescriptionButton").classList.remove("is-loading");
      }
    );
  }
  
  deleteWeekTemplate(week_template_id: number = 0) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let weekTemplate = 0;

    if(week_template_id !== 0) {
      weekTemplate = week_template_id;
    } else {
      weekTemplate = this.weekTemplateID;
    }

    this.apollo
    .mutate({
      mutation: this.deleteWeekTemplateMutation.document,
      variables: {
        template_id: weekTemplate,
        product_id: this.productID,
        id_token: token,
        lender_email: email,
      },
    })
    .subscribe(
      ({ data }) => {
        if(data["deleteProductWeekTemplate"] != null) {
          if(this.updateWeekTemplateFlag === true) {
            this.deletedWeekTemplateFlag.next("update");  
          } else {
            this.deletedWeekTemplateFlag.next("delete");
          }
          if(week_template_id !== 0) {
            this.queryWeekTemplate(); 
          }
          this.toast("Week Template deleted successfully.");
        }
      },
      (error) => {
        this.toastr.error(error.message);
        this.deletedWeekTemplateFlag.next("NA");
        if(this.updateWeekTemplateFlag === true) {
          document.getElementById("weekTemplateUpdateButton").classList.remove("is-loading");
        } else {
          document.getElementById("dayTemplateCreationButton").classList.remove("is-loading");
        }
      }
    );
  }

  createDayTemplate() {
    if(this.updateDayTemplateFlag === true) {
      document.getElementById("dayTemplateUpdateButton").classList.add("is-loading");
      this.deleteDayTemplate();
    } else {
      document.getElementById("dayTemplateCreationButton").classList.add("is-loading");
    }

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let minutes = 0;
    let hours = 0;

    if(this.frmScheduleTemplates.controls.durationMin.value != null && 
      this.frmScheduleTemplates.controls.durationMin.value != "" && 
      (this.frmScheduleTemplates.controls.durationMin.dirty == true || 
      this.updateDayTemplateFlag === true)) {
      minutes = this.frmScheduleTemplates.controls.durationMin.value;
    }

    if(this.frmScheduleTemplates.controls.durationHrs.value != null && 
      this.frmScheduleTemplates.controls.durationHrs.value != "" && 
      (this.frmScheduleTemplates.controls.durationHrs.dirty == true || 
      this.updateDayTemplateFlag === true)) {
        hours = this.frmScheduleTemplates.controls.durationHrs.value;
    }

    if(this.updateDayTemplateFlag !== true) {
      this.deletedDayTemplateFlag.next("create");
    }

    this.deleteDaySubscription = this.deletedDayTemplateFlag.subscribe(
      (data1) => {
        if(data1 === "create" || data1 === "update") {
          this.apollo
          .mutate({
            mutation: this.createDayTemplateMutation.document,
            variables: {
              template_name: this.frmScheduleTemplates.controls.templateName.value,
              product_id: this.productID,
              id_token: token,
              lender_email: email,
              slot_hours: hours,
              slot_minutes: minutes,
              slots: this.checkedSlots
            }
          })
          .subscribe(
            ({ data }) => {
              this.toast("Day Template created successfully.");
              this.frmScheduleTemplates.reset();
              this.daySchedulerComponent.clearCalendar();
              this.queryDayTemplate(); 
              if(this.updateDayTemplateFlag === true) {
                document.getElementById("dayTemplateUpdateButton").classList.remove("is-loading");
                this.updateDayTemplateFlag = false;
              } else {
                document.getElementById("dayTemplateCreationButton").classList.remove("is-loading");
              }
              this.deleteDaySubscription.unsubscribe();
              this.deletedDayTemplateFlag.next("NA");

              this.checkedSlotCount.next(0);
              this.checkedSlotsForDisplay.next([]);
            },
            (error) => {
              this.toastr.error(error.message);
              if(this.updateDayTemplateFlag === true) {
                document.getElementById("dayTemplateUpdateButton").classList.remove("is-loading");
              } else {
                document.getElementById("dayTemplateCreationButton").classList.remove("is-loading");
              }
              this.deleteDaySubscription.unsubscribe();
              this.deletedDayTemplateFlag.next("NA");
            }
          );
        }
      }
    );
  }

  createWeekTemplate () {

    if(this.updateWeekTemplateFlag === true) {
      document.getElementById("weekTemplateUpdateButton").classList.add("is-loading");
      this.deleteWeekTemplate();
    } else {
      document.getElementById("weekTemplateCreationButton").classList.add("is-loading");
    }

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let monday = null;
    let tuesday = null;
    let wednesday = null;
    let thursday = null;
    let friday = null;
    let saturday = null;
    let sunday = null;

    if((document.getElementById("mondayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      monday = (document.getElementById("mondayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("mondayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(monday == 0) monday = null;
    }

    if((document.getElementById("tuesdayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      tuesday = (document.getElementById("tuesdayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("tuesdayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(tuesday == 0) tuesday = null;
    }

    if((document.getElementById("wednesdayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      wednesday = (document.getElementById("wednesdayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("wednesdayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(wednesday == 0) wednesday = null;
    }

    if((document.getElementById("thursdayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      thursday = (document.getElementById("thursdayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("thursdayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(thursday == 0) thursday = null;
    }

    if((document.getElementById("fridayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      friday = (document.getElementById("fridayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("fridayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(friday == 0) friday = null;
    }

    if((document.getElementById("saturdayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      saturday = (document.getElementById("saturdayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("saturdayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(saturday == 0) saturday = null;
    }

    if((document.getElementById("sundayTemplate") as HTMLSelectElement).options.selectedIndex != -1) {
      sunday = (document.getElementById("sundayTemplate") as HTMLSelectElement)
      .options[(document.getElementById("sundayTemplate") as HTMLSelectElement)
      .options.selectedIndex].id.split("_")[1];
      if(sunday == 0) sunday = null;
    }

    if(this.updateWeekTemplateFlag !== true) {
      this.deletedWeekTemplateFlag.next("create");
    }

    this.deleteWeekSubscription = this.deletedWeekTemplateFlag.subscribe(
      (data1) => {
        if(data1 === "create" || data1 === "update") {
          this.deletedWeekTemplateFlag.next("false");
          this.apollo
          .mutate({
            mutation: this.createWeekTemplateMutation.document,
            variables: {
              template_name: this.frmScheduleWeekTemplates.controls.templateName.value,
              product_id: this.productID,
              id_token: token,
              lender_email: email,
              mon_day_template_id: monday,
              tue_day_template_id: tuesday,
              wed_day_template_id: wednesday,
              thur_day_template_id: thursday,
              fri_day_template_id: friday,
              sat_day_template_id: saturday,
              sun_day_template_id: sunday
            },
          })
          .subscribe(
            ({ data }) => {
              this.toast("Week Template created successfully.");
              this.frmScheduleWeekTemplates.reset();
              this.queryWeekTemplate();
              if(this.updateWeekTemplateFlag === true) {
                document.getElementById("weekTemplateUpdateButton").classList.remove("is-loading");
                this.updateWeekTemplateFlag = false;
              } else {
                document.getElementById("weekTemplateCreationButton").classList.remove("is-loading");
              }
              this.deleteWeekSubscription.unsubscribe();
              this.deletedWeekTemplateFlag.next("NA");
            },
            (error) => {
              this.toastr.error(error.message);
              if(this.updateWeekTemplateFlag === true) {
                document.getElementById("weekTemplateUpdateButton").classList.remove("is-loading");
              } else {
                document.getElementById("weekTemplateCreationButton").classList.remove("is-loading");
              }
              this.deleteWeekSubscription.unsubscribe();
              this.deletedWeekTemplateFlag.next("NA");            }
          );
        }
      });
  }

  async updateProduct() {

    document.getElementById("updateProductButton").classList.add("is-loading");
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let address1 = "";
    let address2 = "";
    let landmark = "";
    let areaName = "";
    let pinCode = "";
    let cityName = "";
    let district = "";
    let state = "";

    var hideFromSearchVal = this.frmProductDetails.controls.hideFromSearchTag.value;

    if(this.showAddress == true) {
      address1 = this.frmProductDetails.controls.address1.value;
      address2 = this.frmProductDetails.controls.address2.value;
      landmark = this.frmProductDetails.controls.landmark.value;
      areaName =  this.frmProductDetails.controls.area.value;
      pinCode = this.frmProductDetails.controls.pincode.value;
      cityName = this.frmProductDetails.controls.city.value;
      district = this.frmProductDetails.controls.district.value;
      state = this.frmProductDetails.controls.state.value;
    }

    var result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: this.offerMarkdownDescription,
        check_phone_and_email: true
      },
    }).toPromise();

    if(result["data"]["validateAIText"].toLowerCase().includes("yes")) {
      this.toastr.error("The description that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description or guidelines. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: token,
          input_text: this.offerMarkdownDescription,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          this.offerInputDescription.next(data["replaceAIText"]);
          document.getElementById("updateProductButton").classList.remove("is-loading");
        },
        (error) => {
          document.getElementById("updateProductButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
      return;
    }

    result = await this.apollo
          .mutate({
            mutation: this.validateAITextMutation.document,
            variables: {
              offeror_email: email,
              id_token: token,
              input_text: this.offerMarkdownDescription
            },
          }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      document.getElementById("updateProductButton").classList.remove("is-loading");
      this.toastr.error("The description is not good. " + result["data"]['validateAIText']);
      return;
    }

    result = await this.apollo.mutate({
                  mutation: this.validateAITextMutation.document,
                  variables: {
                    offeror_email: email,
                    id_token: token,
                    input_text: this.offerMarkdownGuidelines,
                    check_phone_and_email: true
                  },
                }).toPromise();

    if(result["data"]['validateAIText'].toLowerCase().includes("yes")) {
      this.toastr.error("The guidelines that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description or guidelines. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: token,
          input_text: this.offerMarkdownGuidelines,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          document.getElementById("updateProductButton").classList.remove("is-loading");
          this.offerInputGuidelines.next(data["replaceAIText"]);
        },
        (error) => {
          document.getElementById("updateProductButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
      return;
    }

    result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: this.offerMarkdownGuidelines
      },
    }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      document.getElementById("updateProductButton").classList.remove("is-loading");
      this.toastr.error("The guidelines is not good. " + result["data"]['validateAIText']);
      return;
    }

    this.apollo
      .mutate({
        mutation: this.updateProductMutation.document,
        variables: {
          product_id: this.productID,
          lender_email: email,
          id_token: token,
          product_description: this.offerHtmlDescription,
          product_markdown_description: this.offerMarkdownDescription,
          markdown_guidelines: this.offerMarkdownGuidelines,
          guidelines: this.offerHtmlGuidelines,
          age: "",
          hideFromSearch: hideFromSearchVal,
          address_line1: address1,
          address_line2: address2,
          landmark: landmark,
          area_name: areaName,
          pin_code: pinCode,
          city: cityName,
          district: district,
          state: state,
          latitude: "",
          longitude: "",
        },
      })
      .subscribe(
        ({ data }) => {
          this.productTitle = this.frmProductDetails.controls.title.value;
          if(this.productID == data["updateProduct"]) {
            


            this.apollo
            .mutate({
              mutation: this.deleteOfferAttributeMutation.document,
              variables: {
                lender_email: email,
                id_token: token,
                product_id: this.productID
              },
            })
            .subscribe(
              ({ data }) => {
                if(this.selectedAttributes != null && this.selectedAttributes.length > 0) {
                  var cntPr = 0;
                  this.selectedAttributes.forEach(attribute => {
                    this.apollo
                    .mutate({
                      mutation: this.createAttributeMutation.document,
                      variables: {
                        lender_email: email,
                        id_token: token,
                        product_id: this.productID,
                        attribute_key: attribute["attribute_key"],
                        attribute_value: attribute["attribute_value"]
                      },
                    })
                    .subscribe(
                      ({ data }) => {
                        cntPr++;
                        if(cntPr == this.selectedAttributes.length) {
                          this.toast("Offer updated successfully with attributes!");
                        }
                      },
                      (error) => {
                        this.toastr.error(error.message);
                      }
                    );
                  });
                  
                } else {
                  this.toast("Offer updated successfully!");
                }
              },
              (error) => {
                this.toastr.error(error.message);
              }
            );
          } else {
            this.toast("Offer updated successfully! A new offer with the same structure is created now. Click on Next button to check the templates.");
          }
          
          if(this.productID != data["updateProduct"]) {
            this.productID = data["updateProduct"];
            this.newProductID.next(data["updateProduct"]);
            this.disableNext = false;
  
            var cnt = 0;
            if(this.selectedAttributes != null && this.selectedAttributes.length > 0) {
              this.selectedAttributes.forEach(attribute => {
                this.apollo
                .mutate({
                  mutation: this.createAttributeMutation.document,
                  variables: {
                    lender_email: email,
                    id_token: token,
                    product_id: this.productID,
                    attribute_key: attribute["attribute_key"],
                    attribute_value: attribute["attribute_value"]
                  },
                })
                .subscribe(
                  ({ data }) => {
                    cnt++;
                    if(cnt == this.selectedAttributes.length) {
                      this.updateTemplates();
                      this.addDayTemplateOnUpdate.subscribe(
                        (data) => {
                          if(data == true) {
                            this.addWeekTemplateOnUpdate.subscribe(
                              (data) => {
                                if(data == true) {
                                  document.getElementById("updateProductButton").classList.remove("is-loading");
                                  this.routeWeb.navigate(['/update-offering/' + this.productID]);
                                }
                              }
                            )
                          }
                        }
                      )
                    }
                  },
                  (error) => {
                    document.getElementById("updateProductButton").classList.remove("is-loading");
                    this.toastr.error(error.message);
                  }
                );
              });
              
            }
  
            if(this.selectedAttributes.length <= 0) {
              this.updateTemplates();
              this.addDayTemplateOnUpdate.subscribe(
                (data) => {
                  if(data == true) {
                    this.addWeekTemplateOnUpdate.subscribe(
                      (data) => {
                        if(data == true) {
                          document.getElementById("updateProductButton").classList.remove("is-loading");
                          this.routeWeb.navigate(['/update-offering/' + this.productID]);
                        }
                      }
                    )
                  }
                }
              );
            }
          } else {
            document.getElementById("updateProductButton").classList.remove("is-loading");
          }
        },
        (error) => {
          document.getElementById("updateProductButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
  }

  async createProduct() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let address1 = "";
    let address2 = "";
    let landmark = "";
    let areaName = "";
    let pinCode = "";
    let cityName = "";
    let district = "";
    let state = "";
    var hideFromSearchVal = this.frmProductDetails.controls.hideFromSearchTag.value;


    document.getElementById("createOfferButton").classList.add("is-loading");

    if(this.showAddress == true) {
      address1 = this.frmProductDetails.controls.address1.value;
      address2 = this.frmProductDetails.controls.address2.value;
      landmark = this.frmProductDetails.controls.landmark.value;
      areaName =  this.frmProductDetails.controls.area.value;
      pinCode = this.frmProductDetails.controls.pincode.value;
      cityName = this.frmProductDetails.controls.city.value;
      district = this.frmProductDetails.controls.district.value;
      state = this.frmProductDetails.controls.state.value;
    }

    var result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: this.offerMarkdownDescription,
        check_phone_and_email: true
      },
    }).toPromise();

    if(result["data"]["validateAIText"].toLowerCase().includes("yes")) {
      this.toastr.error("The description that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description or guidelines. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: token,
          input_text: this.offerMarkdownDescription,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          this.offerInputDescription.next(data["replaceAIText"]);
          document.getElementById("createOfferButton").classList.remove("is-loading");
        },
        (error) => {
          document.getElementById("createOfferButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
      return;
    }

    result = await this.apollo
          .mutate({
            mutation: this.validateAITextMutation.document,
            variables: {
              offeror_email: email,
              id_token: token,
              input_text: this.offerMarkdownDescription
            },
          }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      document.getElementById("createOfferButton").classList.remove("is-loading");
      this.toastr.error("The description is not good. " + result["data"]['validateAIText']);
      return;
    }

    result = await this.apollo.mutate({
                  mutation: this.validateAITextMutation.document,
                  variables: {
                    offeror_email: email,
                    id_token: token,
                    input_text: this.offerMarkdownGuidelines,
                    check_phone_and_email: true
                  },
                }).toPromise();

    if(result["data"]['validateAIText'].toLowerCase().includes("yes")) {
      this.toastr.error("The guidelines that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description or guidelines. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: token,
          input_text: this.offerMarkdownGuidelines,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          document.getElementById("createOfferButton").classList.remove("is-loading");
          this.offerInputGuidelines.next(data["replaceAIText"]);
        },
        (error) => {
          document.getElementById("createOfferButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
      return;
    }

    result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: this.offerMarkdownGuidelines
      },
    }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      document.getElementById("createOfferButton").classList.remove("is-loading");
      this.toastr.error("The guidelines is not good. " + result["data"]['validateAIText']);
      return;
    }

    result = await this.apollo.mutate({
                  mutation: this.validateAITextMutation.document,
                  variables: {
                    offeror_email: email,
                    id_token: token,
                    input_text: this.frmProductDetails.controls.title.value,
                    check_phone_and_email: true
                  },
                }).toPromise();

    if(result["data"]['validateAIText'].toLowerCase().includes("yes")) {
      this.toastr.error("The title that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description or guidelines. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: token,
          input_text: this.frmProductDetails.controls.title.value,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          document.getElementById("createOfferButton").classList.remove("is-loading");
          this.frmProductDetails.patchValue({
          title: data["replaceAIText"]});
        },
        (error) => {
          document.getElementById("createOfferButton").classList.remove("is-loading");
          this.toastr.error(error.message);
        }
      );
      return;
    }

    result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: token,
        input_text: this.frmProductDetails.controls.title.value
      },
    }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      document.getElementById("createOfferButton").classList.remove("is-loading");
      this.toastr.error("The title is not good. " + result["data"]['validateAIText']);
      return;
    }

    this.apollo
    .mutate({
      mutation: this.createProductMutation.document,
      variables: {
        lender_email: email,
        id_token: token,
        category_group: this.frmProductDetails.controls.categoryGroup.value,
        category: this.frmProductDetails.controls.category.value,
        sub_category1: this.frmProductDetails.controls.majorCategory.value,
        sub_category2: this.frmProductDetails.controls.minorCategory.value,
        title: this.frmProductDetails.controls.title.value,
        product_name: this.frmProductDetails.controls.productName.value,
        product_description: this.offerHtmlDescription,
        product_markdown_description: this.offerMarkdownDescription,
        markdown_guidelines: this.offerMarkdownGuidelines,
        guidelines: this.offerHtmlGuidelines,
        age: "",
        hideFromSearch: hideFromSearchVal,
        address_line1: address1,
        address_line2: address2,
        landmark: landmark,
        area_name: areaName,
        pin_code: pinCode,
        city: cityName,
        district: district,
        state: state,
        latitude: "",
        longitude: "",
      },
    })
    .subscribe(
      ({ data }) => {
        this.categoryID.next(this.getCagetoryId(
          this.frmProductDetails.controls.category.value, 
          this.frmProductDetails.controls.majorCategory.value,
          this.frmProductDetails.controls.minorCategory.value));
        this.productTitle = this.frmProductDetails.controls.title.value;
        this.productID = data["createProduct"];
        this.newProductID.next(data["createProduct"]);
        this.disableNext = false;
        this.toast("Offer created successfully! Click on Next button to check the templates.");
        this.frmProductDetails.setErrors({"incorrect": true});

        this.productCreated.next(true);

        if(this.selectedAttributes != null && this.selectedAttributes.length > 0) {
          this.selectedAttributes.forEach(attribute => {
            this.apollo
            .mutate({
              mutation: this.createAttributeMutation.document,
              variables: {
                lender_email: email,
                id_token: token,
                product_id: this.productID,
                attribute_key: attribute["attribute_key"],
                attribute_value: attribute["attribute_value"]
              },
            })
            .subscribe(
              ({ data }) => {},
              (error) => {
                this.toastr.error(error.message);
              }
            );
          });
          
        }
        document.getElementById("createOfferButton").classList.remove("is-loading");
      },
      (error) => {
        document.getElementById("createOfferButton").classList.remove("is-loading");
        this.toastr.error(error.message);
      }
    );
  }

  getCagetoryId(category: string, sub_category1: string, sub_category2: string) {
    var categoryId = null;
    this.getCategoryQuery.categoryData["getProductCategories"].forEach(cat => {
      if(cat["category"] == category && cat["sub_category1"] == sub_category1 && cat["sub_category2"] == sub_category2) {
        categoryId = cat.category_id;
      }
    });
    return categoryId;
  }

  callGetAreaQuery(event: any) {
    let pincode = event.target.value;
    this.apollo
      .query({
        query: this.getAreaQuery.document,
        variables: {
          pin_code: pincode,
          area: "",
          city: "",
          district: "",
          state: "",
          country: "",
          countryAbbreviation: "",
        },
      })
      .subscribe(
        ({ data }) => {
          this.getAreaQuery.populateAreaData(data);
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  timeSchedularDurationChangeHandler($evt) {
    if ($evt.target.name === "durationHrs")
      this.daySchedulerComponent.hours = $evt.target.value;
    else this.daySchedulerComponent.mins = $evt.target.value;
    this.daySchedulerComponent.clearCalendar();
    this.dayTemplateValidCheck = true;
  }

  toast(msg){
    this.toastr.success(msg);
  }
}

export interface NewOfferingsComponentInterface {
  addDayScheduleSlot: (string, checkedSlots) => void;
  updateSchedule: (number) => void;
}
