import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class CreateProductMutation extends Mutation {
  document = gql`
    mutation createProductMutation(
      $lender_email: String!
      $id_token: String!
      $category_group: String
      $category: String
      $sub_category1: String
      $sub_category2: String
      $title: String!
      $hideFromSearch: Boolean
      $product_name: String!
      $product_description: String!
      $product_markdown_description: String
      $guidelines: String
      $markdown_guidelines: String
      $age: Int
      $address_line1: String
      $address_line2: String
      $landmark: String
      $area_name: String
      $pin_code: String
      $city: String
      $district: String
      $state: String
      $latitude: String
      $longitude: String
    ) {
      createProduct(
        lender_email: $lender_email
        id_token: $id_token
        category_group: $category_group
        category: $category
        sub_category1: $sub_category1
        sub_category2: $sub_category2
        title: $title
        product_name: $product_name
        hide_from_search: $hideFromSearch
        product_description: $product_description
        product_markdown_description: $product_markdown_description
        guidelines: $guidelines
        markdown_guidelines: $markdown_guidelines
        age: $age
        address_line1: $address_line1
        address_line2: $address_line2
        landmark: $landmark
        area_name: $area_name
        pin_code: $pin_code
        city: $city
        district: $district
        state: $state
        latitude: $latitude
        longitude: $longitude
      )
    }
  `;
}
