<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="offering-report"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="offering-report"></app-header-mobile>



<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Offering Report" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-offerings-sidebar></app-offerings-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <div class="container mt-5">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field mw-40 max-vw-100">
                <label class="label">Select Offering</label>
                <p class="control is-expanded has-icons-right">
                  <select class="input" name="offerList" id="offerList" (change)="checkButtonActivation()">
                    <option selected value="">Select</option>
                    <option *ngFor="let product of productList" [value]="product['id']">{{product['title']}}<span *ngIf="product['version'] != 1">&nbsp;({{product['version']}})</span></option>
                  </select>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </p>
              </div>
              <div class="field mw-40 max-vw-50">
                <label class="label">Select Schedule Type</label>
                <p class="control is-expanded has-icons-right">
                  <select class="input" name="scheduleTypeList" id="scheduleTypeList" (change)="checkButtonActivation()">
                    <option selected value="">Select</option>
                    <option value="hourly">Hourly</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="custom">Custom</option>
                  </select>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </p>
              </div>
              <div class="field mw-20 max-vw-50 d-flex align-items-end">
                <button [disabled]="reportButtonDisable" class="button is-black" (click)='fetchReport()'>
                  <span> Fetch Report</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br/>
        
        <div [hidden]="hideReport" class="container mt-5">
          <div class="row">
            <div class="col-md-12 h4 grey">
              Current Year
            </div>    
          </div>
          <div class="row">
            <div class="col-md-12 has-text-grey">
              {{currentYear}}
            </div>    
          </div>
        </div>
        
        <div [hidden]="hideReport" class="container mt-2">
          <div class="row">
            <div class="col-md-12 h7 grey">
              Year-to-date total earnings (after tax deduction)
            </div>    
          </div>
          <div class="row">
            <div class="col-md-12 has-text-grey">
              {{totalAnnualEarning | curency}}
            </div>    
          </div>
        </div>
        
        <div [hidden]="hideReport" class="container mt-4">
          <div class="row">
            <div class="col-md-12 h4 grey">
              Current Month
            </div>    
          </div>
          <div class="row">
            <div class="col-md-12 has-text-grey">
              {{currentMonthDates}}
            </div>    
          </div>
        </div>
        
        <div [hidden]="hideReport" class="container mt-2">
          <div class="row">
            <div class="col-md-12 h7 grey">
              Month-to-date unpaid total earnings (after tax deduction)
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 has-text-grey">
              {{totalMonthlyUnpaid | curency}}
            </div>    
          </div>
        </div>
        
        <br/>
        <div [hidden]="hideReport" class="container mt-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 h4 grey">
              Offer Earning's Graph
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 text-center">
              <div class="input-group pb-2">
                {{"Start Date: " | translate}}&nbsp;<input id="graphStartDate" class="form-control" placeholder="yyyy-mm-dd"
                      name="dpstart" [(ngModel)]="model_start" ngbDatepicker #dpstart="ngbDatepicker">
                <button class="btn-sm btn-secondary calendar" (click)="dpstart.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 text-center pb-2">
              <button class="button is-info" (click)="getNewGraphData()" type="button"> Refresh Graph</button>
            </div>
            <div class="dropdown col-md-4 col-sm-12">
              <button class="button is-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span> Select graph</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="changeGraphType(graph_option)" *ngFor="let graph_option of graph_options">{{graph_option}}</a>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-12 col-sm-12">
              <div style="display: block">
                <canvas baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [legend]="barChartLegend"
                        [chartType]="barChartType"
                        >
                </canvas>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div [hidden]="hideReport" class="container mt-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 h4 grey">
              Offer Report
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-4 col-sm-12 text-center">
              <div class="input-group pb-2">
                {{"Start Date: " | translate}}&nbsp;<input id="reportStartDate" class="form-control" placeholder="yyyy-mm-dd"
                      name="dprstart" [(ngModel)]="model_report_start" ngbDatepicker #dprstart="ngbDatepicker">
                <button class="btn-sm btn-secondary calendar" (click)="dprstart.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 text-center">
              <div class="input-group pb-2">
                {{"End Date: " | translate}}&nbsp;<input id="reportEndDate" class="form-control" placeholder="yyyy-mm-dd"
                      name="dprend" [(ngModel)]="model_report_end" ngbDatepicker #dprend="ngbDatepicker">
                <button class="btn-sm btn-secondary calendar" (click)="dprend.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 text-center pb-2">
              <button class="button is-info" (click)="getNewReportData()" type="button"> Refresh Report</button>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-12 col-sm-12">
              <div class="reportload">
                <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr class="border-0">
                        <td class="border-0">
                          <table class="table border-0">
                            <tr class="border-0">
                              <th scope="col" width="2%" class="border-0">#</th>
                              <th scope="col" width="20%" class="border-0">Schedule Name</th>
                              <th scope="col" width="14%" class="border-0">Schedule Start Date</th>
                              <th scope="col" width="14%" class="border-0">Schedule End Date</th>
                              <th scope="col" width="15%" class="border-0">Total Earning</th>
                              <th scope="col" width="10%" class="border-0">Total Earning After Tax</th>
                              <th scope="col" width="10%" class="border-0">Total Commission</th>
                              <th scope="col" width="8%" class="border-0">Total confirmed bookings</th>
                              <th scope="col" width="7%" class="border-0">Total canceled bookings</th>
                            </tr>
                          </table>
                          </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let scheduleData of productScheduleReportData | keyvalue">
                        <td class="control center">
                          <table class="table border-0">
                            <tbody>
                              <tr class="border-0">
                                <td width="2%">
                                  <a class="border-0" data-toggle="collapse" href="#scheduleDetail_{{scheduleData.key}}" id="offerorDesc" role="button" aria-expanded="false">
                                    <button type="button" class="btn-sm border-0">
                                      <span class="fas fa-chevron-down"></span>
                                    </button>
                                  </a>
                                </td>
                                <td width="10%">{{scheduleData.value["schedule_name"]}}</td>
                                <td width="14%">{{scheduleData.value["schedule_start_date"]}}</td>
                                <td width="14%">{{scheduleData.value["schedule_end_date"]}}</td>
                                <td width="15%">{{scheduleData.value["total_price"] | curency}}</td>
                                <td width="15%">{{scheduleData.value["total_price_after_tax"] | curency}}</td>
                                <td width="15%">{{scheduleData.value["total_commission"] | curency}}</td>
                                <td width="8%">{{scheduleData.value["confirmed_product_locks_total"]}}</td>
                                <td width="7%">{{scheduleData.value["rejected_product_locks_total"]}}</td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table border-0">
                            <tbody>
                              <tr class="border-0">
                                <td>
                                  <div class="col-md-12 icon-box border-0">
                                  <div class="collapse border-0" id="scheduleDetail_{{scheduleData.key}}">
                                    <div class="card card-body border-0">
                                      <table class="table table-bordered table-striped mb-0">
                                        <thead>
                                          <tr>
                                            <th scope="col">Lock Start Date</th>
                                            <th scope="col">Lock End Date</th>
                                            <th scope="col">Offeree Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Price After Tax
                                            <th scope="col">Zigwik Commission</th>
                                            <th scope="col">Lock Type</th>
                                            <th scope="col">Slot information</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let data of scheduleData.value['product_locks']">
                                            <td>
                                              {{data["start_date"]}}
                                            </td>
                                            <td>
                                              {{data["end_date"]}}
                                            </td>
                                            <td>
                                              <a [routerLink]="['/public-profile/', data.offeree_id]">
                                                {{data["offeree_first_name"]}}&nbsp;{{data["offeree_last_name"]}}
                                              </a>
                                            </td>
                                            <td>
                                              {{data["actual_price"] - data["refund_amount"] | curency}}
                                            </td>
                                            <td>
                                              {{data["price_after_tax"] - data["commission_after_tax"] | curency}}
                                            </td>
                                            <td>
                                              {{data["commission_before_tax"] | curency}}
                                            </td>
                                            <td>
                                              <span *ngIf="data['lock_type'] == 'hard_lock'">{{"Lock is not yet confirmed" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'confirmed_lock'">{{"Lock confirmed" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'lock_fulfilled'">{{"Lock is fulfilled" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'offeror_rejected_hard_lock'">{{"Lock was rejected by you" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'auto_rejected_hard_lock'">{{"Lock was rejected automatically" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'offeree_rejected_paid_lock_half_refund'">{{"Offeree rejected lock with half refund" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'offeree_rejected_paid_lock_full_refund'">{{"Offeree rejected lock with full refund" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'offeree_rejected_paid_lock_no_refund'">{{"Offeree rejected lock with no refund" | translate}}</span>
                                              <span *ngIf="data['lock_type'] == 'offeree_grievance_refund_lock'">{{"Offeree raised Grievance for the lock" | translate}}</span>
                                            </td>
                                            <td>
                                              <ng-template #lockSlotsContent let-modal>
                                                <div class="modal-header">
                                                  <h4 class="modal-title" id="modal-basic-title">Lock slots</h4>
                                                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                  <table class="table table-bordered table-striped mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">Slot Date</th>
                                                        <th scope="col">Slot Start Time</th>
                                                        <th scope="col">Slot End Time</th>
                                                        <th scope="col">Slot Duration</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let slot of data.slots">
                                                        <td>{{slot["actual_date"]}}</td>
                                                        <td>
                                                          <span *ngIf="('0' + slot['start_hour']).length == 2">0{{slot["start_hour"]}}</span><span *ngIf="('0' + slot['start_hour']).length > 2">{{slot["start_hour"]}}</span>:<span *ngIf="('0' + slot['start_minute']).length == 2">0{{slot["start_minute"]}}</span><span *ngIf="('0' + slot['start_minute']).length > 2">{{slot["start_minute"]}}</span>
                                                        </td>
                                                        <td>
                                                          <span *ngIf="('0' + slot['end_hour']).length == 2">0{{slot["end_hour"]}}</span><span *ngIf="('0' + slot['end_hour']).length > 2">{{slot["end_hour"]}}</span>:<span *ngIf="('0' + slot['end_minute']).length == 2">0{{slot["end_minute"]}}</span><span *ngIf="('0' + slot['end_minute']).length > 2">{{slot["end_minute"]}}</span>
                                                        </td>
                                                        <td>
                                                          {{slot["slot_hours"]}} hour(s), {{slot["slot_minutes"]}} minute(s)
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </ng-template>
                                              <a (click)="openModal(lockSlotsContent)"><i class="fas fa-info-circle text-secondary"></i></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>
