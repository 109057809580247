import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class UpdateHideFromSearchMutation extends Mutation {
  document = gql`
    mutation UpdateHideFromSearchMutation(
      $product_id: Int!
      $lender_email: String!
      $id_token: String!
      $hideFromSearch: Boolean!
    ) {
        updateHideFromSearch(
        product_id: $product_id
        lender_email: $lender_email
        id_token: $id_token
        hide_from_search: $hideFromSearch
      )
    }
  `;
}
