import { Component, Input, OnInit } from "@angular/core";
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { TrackHomeViewMutation } from "src/app/services/graphql/trackHomeView.graphql";
import { Subscription } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
import { Apollo } from "apollo-angular";
import { SessionService } from "src/app/services/session.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SessionConstants } from "src/app/constants/app.constants";
import { BehaviorSubject } from "rxjs";
import AOS from 'aos';

@Component({
  selector: "app-home",
  // templateUrl: "./home.component.html",
  templateUrl: "./home.temp.component.html",
  styleUrls: ["./home.component.scss"],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      border-radius: 0.25rem;
      display: inline-block;
      width: 2rem;
    }
    .custom-day:hover, .custom-day.focused {
      background-color: #e6e6e6;
    }
    .weekend {
      background-color: #f0ad4e;
      border-radius: 1rem;
      color: white;
    }
    .hidden {
      display: none;
    }
  `]
})
export class HomeComponent implements OnInit {
  subscription: Subscription;
  homeProducts: any[];
  responseErrorMessage = "";
  catogeryList = [];
  selectedFilter:string = null
  isLoading:boolean = false;
  allCatogeryList:any = [];
  currentDate = Date.now();
  currentDate1 = new Date((new Date()).getFullYear(), 11, 31);
  selectedStartDate: Date = new Date(this.currentDate);
  selectedEndDate: Date = new Date(this.currentDate1);
  search_start_date = this.selectedStartDate.getFullYear().toString() + "-" + (this.selectedStartDate.getMonth() + 1).toString() + "-" + this.selectedStartDate.getDate().toString();
  search_end_date = this.selectedEndDate.getFullYear().toString() + "-" + (this.selectedEndDate.getMonth() + 1).toString() + "-" + this.selectedEndDate.getDate().toString();

  pageloader = null;

  noOffers = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private apollo: Apollo,
    private getProductListQuery: GetProductListQuery,
    private authService:AuthService,
    private breakpointObserver: BreakpointObserver,
    private trackHomeViewMutation: TrackHomeViewMutation,
    private sessionService: SessionService
    ) {

    }

  ngOnInit() {
    AOS.init({disable: 'mobile'});
    this.isLoading = true;
    this.getPopularProductList();
    
    this.breakpointObserver.observe([
      "(max-width: 785px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        document.getElementById("backgroundVideo").remove();        
      }
    });

    this.pageloader = document.getElementById("pageloader");

    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");

      this.apollo
            .mutate({
              mutation: this.trackHomeViewMutation.document,
              variables: {
                masked_id: maskedUserData,
                user_id: user_id
              },
          }).subscribe(({ data }) => {});
    }

    this.subscription = this.getProductListQuery.__prodListDataLoadedObservble.subscribe(
      (data) => {
        if (data === EventConstants.CONST_PRODLIST_HOME_LOADED) {
          if(this.pageloader.classList.contains("is-active")) {
            this.pageloader.classList.remove("is-active");
          }
          this.getProductListQuery.responseErrorMessage;
          this.responseErrorMessage = "";
          this.homeProducts = [];
          if (this.getProductListQuery.responseErrorMessage) {
            this.responseErrorMessage = this.getProductListQuery.responseErrorMessage;
            console.log(this.responseErrorMessage);
          } else {
            this.homeProducts = this.getProductListQuery.productListData;
          }
          if(this.homeProducts.length <= 0) {
            this.noOffers.next(true);
          } else {
            this.noOffers.next(false);
          }
        }
        this.isLoading = false;
      }
    );

  this.authService.catogeryList.subscribe(res => this.catogeryList = res);
  
  }

  getPopularProductList():void{
    this.apollo
      .query({
        query: this.getProductListQuery.document,
        variables: {
          title: "",
          category_group: this.selectedFilter || "",
          start_date: "",
          end_date: "",
          offset: 0,
          count: 6,
          randomize_search_flag: true
        },
      })
      .subscribe(
        ({ data }) => {
          console.log("data received");

          if (data["getProductList"]) {
            this.getProductListQuery.populateProductList(data, true, true);
          }
        },
        (error) => {
          console.log(error.message);
          this.getProductListQuery.populateProductList(error.message, false, true);
        }
      );
  }

  slideConfigCarousel = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,

    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,

    pauseOnHover: false,

    pauseOnDotsHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slideOffersConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,

    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slideCategoryConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    infinite: false,

    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
