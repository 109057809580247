import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class UpdateProductMutation extends Mutation {
  document = gql`
    mutation UpdateProductMutation(
      $product_id: Int!
      $lender_email: String!
      $id_token: String!
      $product_description: String
      $product_markdown_description: String
      $guidelines: String
      $markdown_guidelines: String
      $age: Int
      $hideFromSearch: Boolean
      $address_line1: String
      $address_line2: String
      $landmark: String
      $area_id: Int
      $area_name: String
      $pin_code: String
      $city: String
      $district: String
      $state: String
      $latitude: String
      $longitude: String
    ) {
      updateProduct(
        product_id: $product_id
        lender_email: $lender_email
        id_token: $id_token
        product_description: $product_description
        product_markdown_description: $product_markdown_description
        guidelines: $guidelines
        markdown_guidelines: $markdown_guidelines
        age: $age
        hide_from_search: $hideFromSearch
        inactive_confirmation: false
        address_line1: $address_line1
        address_line2: $address_line2
        landmark: $landmark
        area_name: $area_name
        pin_code: $pin_code
        city: $city
        area_id: $area_id
        district: $district
        state: $state
        latitude: $latitude
        longitude: $longitude
      )
    }
  `;
}
