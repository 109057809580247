import { Component, OnInit } from '@angular/core';
import { KatoraService } from 'src/app/services/katora.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { FinalPaymentRedirection } from 'src/app/services/graphql/finalPaymentRedirection.graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from "rxjs";
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  private masked_hash
  public successful_status = new BehaviorSubject<string>(
    ""
  );
  shared_order_id: String = ""

  pageloader = null;

  constructor(
    private katoraService : KatoraService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private finalPaymentRedirection: FinalPaymentRedirection,
    private apollo: Apollo
    ) {
    localStorage.removeItem('cartProducts');
    localStorage.removeItem('orderData');
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.masked_hash = params.get('masked_hash');
      }
    );

    this.pageloader = document.getElementById("pageloader");

    let token = this.sessionService.getKeyValues("token", "id_token");
    var email = this.sessionService.getLocalKeyValues("user", "email");
    let anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(email === null || email === undefined || email === "") {
      email = anonymousEmail;
    }

    var unmasked = atob(this.masked_hash);
    var unmashed_array = unmasked.match(/^(.*?)(ORD.*)$/);

    this.shared_order_id = unmashed_array[2];


    try {
      var result = await this.apollo
      .mutate({
        mutation: this.finalPaymentRedirection.document,
        variables: {
          offeree_email: email,
          id_token: token,
          masked_hash: this.masked_hash
        }
      }).toPromise();

      if(result != null) {
        let data = result["data"];
          if(this.pageloader.classList.contains("is-active")) {
            this.pageloader.classList.remove("is-active");
          }
          if (data["handleFinalPaymentRedirection"]) {
            if(data["handleFinalPaymentRedirection"].includes('not successful')) {
              this.successful_status.next("failed_untouched");
            } else {
              this.successful_status.next("success_untouched");
            }
          }
      }
    } catch (error) {
          if(this.pageloader.classList.contains("is-active")) {
            this.pageloader.classList.remove("is-active");
          }
          if(error.message.includes('not successful')) {
            this.successful_status.next("failed_touched");
          } else {
            this.successful_status.next("success_touched");
          }
        }
    // $result = await this.apollo
    //   .mutate({
    //     mutation: this.finalPaymentRedirection.document,
    //     variables: {
    //       offeree_email: email,
    //       id_token: token,
    //       masked_hash: this.masked_hash
    //     }
    //   }).toPromise();



    //   .subscribe(
    //     ({ data }) => {
    //       if(this.pageloader.classList.contains("is-active")) {
    //         this.pageloader.classList.remove("is-active");
    //       }
    //       if (data["handleFinalPaymentRedirection"]) {
    //         if(data["handleFinalPaymentRedirection"].includes('not successful')) {
    //           this.successful_status.next("failed_untouched");
    //         } else {
    //           this.successful_status.next("success_untouched");
    //         }
    //       }
    //     },
    //     (error) => {
    //       if(this.pageloader.classList.contains("is-active")) {
    //         this.pageloader.classList.remove("is-active");
    //       }
    //       if(error.message.includes('not successful')) {
    //         this.successful_status.next("failed_touched");
    //       } else {
    //         this.successful_status.next("success_touched");
    //       }
    //     }
    //   );
  }

}
