<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="approve-offering"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="approve-offering"></app-header-mobile>

<div class="container-fuild">
    <nav aria-label="breadcrumb">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/'">{{ "Home" | translate }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Approve Offering" | translate }}
          </li>
        </ol>
      </div>
    </nav>
  </div>
  <section class="pro-content profile-content">
    <div class="container">
    <div class="row">
        <div class="pro-heading-title">
            <h1>
            {{ "Approve Pending Requests" | translate }}
            </h1>
        </div>
        </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <app-offerings-sidebar></app-offerings-sidebar>
        </div>
        <div class="col-12 col-lg-9">
            <div class="container" *ngIf="(this.productLocks | async)?.length <= 0">
                No Request.
              </div>
              <div class="approveoffering">
                <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
                    <div class="container" *ngIf="(this.productLocks | async)?.length > 0">
                        <table cellpadding="0" cellspacing="0" border="0" class="dataTable table table-bordered" id="approverequesttable">
                            <thead>
                                <tr>
                                  <table class="table border-0">
                                    <tr class="border-0">
                                      <th width="5%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                      <th width="25%">Offer Title</th>
                                      <th width="15%">Offeree Name&nbsp;&nbsp;</th>
                                      <th width="10%">Schedule Type</th>
                                      <th width="7%">Start Date</th>
                                      <th width="8%">End Date</th>
                                      <th width="5%">Price Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                      <th width="5%">Zigwik Service charge</th>
                                      <th width="20%">#</th>
                                    </tr>
                                  </table>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let productLock of productLocks | async">
                                    <td class= "control center">
                                        <table class="table border-0">
                                            <tbody>
                                              <tr class="border-0">
                                                <td width="5%">
                                                    <a class="border-0" data-toggle="collapse" href="#productLock_{{productLock['lock']['id']}}" id="offerorDesc" role="button" aria-expanded="false">
                                                        <button type="button" class="btn-sm border-0">
                                                          <span class="fas fa-chevron-down"></span>
                                                        </button>
                                                    </a>
                                                </td>
                                                <td width="25%">
                                                    <a [routerLink]="'/offer/'+productLock['lock']['product_id']">{{productLock['lock']["title"]}}</a>
                                                </td>
                                                <td width="15%"><a [routerLink]="'/public-profile/'+productLock['lock']['offeree_id']">{{productLock['lock']["offeree_first_name"]}} {{productLock['lock']["offeree_last_name"]}}</a></td>
                                                <td width="10%">{{productLock['lock']["schedule_frequency"]}}</td>
                                                <td width="7%">{{productLock['lock']["start_date"]}}</td>
                                                <td width="8%">{{productLock['lock']["end_date"]}}</td>
                                                <td width="5%">{{productLock['lock']["actual_price"] | curency}}</td>
                                                <td width="5%">{{productLock['lock']["commission"] | curency}}</td>
                                                <td width="20%">
                                                  <ng-template #LockApprovalContent let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Provide Offeree Comments</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      <b>{{ "Provide Offeree Comments" | translate }}</b><br />
                                                      Although optional, it would be great if you can provide any comments to the Offeree. (250 characters max)
                                                      <br/><br/>
                                                      <textarea #productLockApproveText width="100%" id="productLockApproveText_{{productLock['lock']['id']}}" maxlength="250"></textarea>
                                                      <br />
                                                      <button class="button is-small is-success m-2" (click)="changeOfferorStatus(productLock['order_id'], productLock['lock']['id'], 'success', productLockApproveText.value)">Approve</button>
                                                    </div>
                                                  </ng-template>
                                                  <ng-template #LockRejectContent let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Provide Offeree Comments</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      <b>{{ "Provide Offeree Comments" | translate }}</b><br />
                                                      Please provide rejection reason for the Offeree. (250 characters max)
                                                      <br/><br/>
                                                      <textarea #productLockRejectText width="100%" id="productLockRejectText_{{productLock['lock']['id']}}" maxlength="250"></textarea>
                                                      <br />
                                                      <button class="button is-small is-danger m-2" (click)="changeOfferorStatus(productLock['order_id'], productLock['lock']['id'], 'fail', productLockRejectText.value)">&nbsp;&nbsp;Reject&nbsp;</button>                                                
                                                    </div>
                                                  </ng-template>
                                                    <button class="button is-small is-success m-2" (click)="openModal(LockApprovalContent)">Approve</button>
                                                    <button class="button is-small is-danger m-2" (click)="openModal(LockRejectContent)">&nbsp;&nbsp;Reject&nbsp;</button>                                                
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>




                                          <table class="table border-0">
                                            <tbody>
                                              <tr class="border-0">
                                                <td>
                                                  <div class="col-md-12 icon-box border-0">
                                                  <div class="collapse border-0" id="productLock_{{productLock['lock']['id']}}">
                                                    <div class="card card-body border-0">
                                                      <table class="w-auto border-0">
                                                        <tr class="border-0">
                                                          <th width="30%">Additional&nbsp;Guidelines:</th>
                                                          <td width="70%" valign="bottom">{{ productLock.lock.schedule_guidelines }}</td>
                                                        </tr>
                                                        <tr class="border-0">
                                                          <th width="30%">Maximum&nbsp;offer&nbsp;count:</th>
                                                          <td width="70%" valign="bottom">{{ productLock.lock.product_count }}</td>
                                                        </tr>
                                                        <tr class="border-0">
                                                          <th width="30%">Approval&nbsp;days&nbsp;for&nbsp;Offer:</th>
                                                          <td width="70%" valign="bottom">{{ productLock.lock.buffer_days }}</td>
                                                        </tr>
                                                        <tr class="border-0">
                                                          <th width="30%">Full&nbsp;refund&nbsp;before:</th>
                                                          <td width="70%" valign="bottom">{{ productLock.lock.full_refund_date }}</td>
                                                        </tr>
                                                        <tr class="border-0">
                                                          <th width="30%">Half&nbsp;refund&nbsp;before:</th>
                                                          <td width="70%" valign="bottom">{{ productLock.lock.half_refund_date }}</td>
                                                        </tr>
                                                        <tr class="border-0">
                                                          <th width="30%">Slot(s):</th>
                                                          <td width="70%" valign="bottom">
                                                            <table class="table table-bordered table-striped">
                                                                <tr>
                                                                  <th>&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                  <th>Duration</th>
                                                                  <th>Start&nbsp;time</th>
                                                                  <th>End&nbsp;time</th>
                                                                </tr>
                                                              <tbody>
                                                                <tr *ngFor="let slot of productLock.lock.slots">
                                                                  <td>&nbsp;{{slot["actual_date"]}}</td>
                                                                  <td>
                                                                    {{slot["slot_hours"]}}&nbsp;hour&nbsp;{{slot["slot_minutes"]}}&nbsp;minute
                                                                  </td>
                                                                  <td>
                                                                    <ng-template [ngIf]="slot['start_hour'].toString().length < 2">0{{slot["start_hour"]}}</ng-template><ng-template [ngIf]="slot['start_hour'].toString().length >= 2">{{slot["start_hour"]}}</ng-template>:<ng-template [ngIf]="slot['start_minute'].toString().length < 2">0{{slot["start_minute"]}}</ng-template><ng-template [ngIf]="slot['start_minute'].toString().length >= 2">{{slot["start_minute"]}}</ng-template>
                                                                  </td>
                                                                  <td>
                                                                    <ng-template [ngIf]="slot['end_hour'].toString().length < 2">0{{slot["end_hour"]}}</ng-template><ng-template [ngIf]="slot['end_hour'].toString().length >= 2">{{slot["end_hour"]}}</ng-template>:<ng-template [ngIf]="slot['end_minute'].toString().length < 2">0{{slot["end_minute"]}}</ng-template><ng-template [ngIf]="slot['end_minute'].toString().length >= 2">{{slot["end_minute"]}}</ng-template>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                                  </td>
                                                  </tr>
                                                  </tbody>
                                                  </table>


                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  </section>




<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>