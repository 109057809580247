import { Component, OnInit, ViewChild} from '@angular/core';
import { EventConstants } from 'src/app/constants/app.constants';
import { GetProductListQuery } from 'src/app/services/graphql/getProductList.graphql';
import { Subscription } from "rxjs";
import { FilterSerachComponent } from "../../filter-serach/filter-serach/filter-serach.component";
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 
@Component({
  selector: "app-search-page",
  templateUrl: "./search-page.component.html",
  styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent implements OnInit {
  subscription: Subscription;
  plists: any[];
  responseErrorMessage = "";
  prodImgLinkData: any;
  imgLink: any;
  prodImgLinkFlag: boolean = false;
  weekly_price: any;
  hour_price: any;
  month_price: any;
  daily_price: any;
  week: boolean;
  hour:boolean;
  month: boolean;
  daily: boolean;
  noProduct = false;

  offset = 0;
  page_count = 12;
  url:string;
  urlData:any;

  paramCatgoryGroup: string;
  paramCatgory: string;
  paramSubCatgory1: string;
  paramSubCatgory2: string;
  paramStartDate: string;
  paramEndDate: string;
  paramKeyword: string;
  paramFrequency: string;
  paramOffset: number;
  paramCount: number;


  @ViewChild(FilterSerachComponent, { static: true })
  filterSearchComponent: FilterSerachComponent;

  constructor(
    private getProductListQuery: GetProductListQuery,
    private router:Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
    ) {
      let data = this.router.getCurrentNavigation().extras.state?.['response'];
      this.url = data?.url
      this.urlData = data
    
      this.route.queryParams.subscribe(params => {
          this.paramCatgoryGroup = params['categorygroup'];
          this.paramCatgory = params['category'];
          this.paramSubCatgory1 = params['subcategory1'];
          this.paramSubCatgory2 = params['subcategory2'];
          this.paramStartDate = params['startdate'];
          this.paramEndDate = params['enddate'];
          this.paramKeyword = params['keyword'];
          this.paramFrequency = params['frequency'];
          this.paramOffset = params['offset'];
      });

      this.offset = this.paramOffset == null ? 0 : this.paramOffset;
  }

  setOffset(value) {
    this.offset = value;
  }

  setPageCount(value) {
    this.page_count = value;
  }

  ngOnInit() {

    if(this.paramCatgoryGroup != null ||
      this.paramCatgory != null ||
      this.paramSubCatgory1 != null ||
      this.paramSubCatgory2 != null ||
      this.paramStartDate != null ||
      this.paramEndDate != null ||
      this.paramKeyword != null ||
      this.paramFrequency != null ||
      this.paramOffset != null) {

        var today = new Date();

        this.paramStartDate = this.paramStartDate == null ? today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() : this.paramStartDate;
        this.paramEndDate = this.paramEndDate == null ? (today.getFullYear() + 1) + "-" + (today.getMonth() + 1) + "-" + today.getDate() : this.paramEndDate;
        this.paramOffset = this.paramOffset == null ? 0 : this.paramOffset;
        this.offset = this.paramOffset;

      this.filterSearchComponent.productListLoad(
        this.paramKeyword,
        this.paramCatgoryGroup,
        this.paramFrequency,
        this.paramStartDate,
        this.paramEndDate,
        this.paramCatgory || null,
        this.paramSubCatgory1 || null,
        this.paramSubCatgory2 || null,
        this.paramOffset,
        true
      );
    }

    this.subscription = this.getProductListQuery.__prodListDataLoadedObservble.subscribe(
      (data) => {
        if (data === EventConstants.CONST_PRODLIST_LOADED) {
          this.responseErrorMessage = "";

          if (this.getProductListQuery.responseErrorMessage) {
            this.responseErrorMessage = this.getProductListQuery.responseErrorMessage;
          }
          else {
            this.plists = this.getProductListQuery.productListData;
            // this.imgLink = this.getProductListQuery.productListLinkGroup;
            // for (let i = 0; i <= this.plists.length; i++) {

            // }
            // for (let i = 0; i <= this.imgLink.length; i++) {
            //   if (this.imgLink[i] == null) {
            //     this.prodImgLinkFlag = true;
            //     }   
            //  }
            //  this.weekly_price = this.getProductListQuery.productListWeekGroup;
            //   for (let i = 0; i <= this.weekly_price.length; i++) {
            //   if (this.weekly_price[i] !== null) {
            //     this.week = true;
            //       }  
            //    }
            //  this.hour_price = this.getProductListQuery.productListHourGroup;
            //  for (let i = 0; i <= this.weekly_price.length; i++) {
            //  if(this.hour_price !== null){
            //   this.hour=true;
            //   }
            // }
            //  this.month_price = this.getProductListQuery.productListMonthGroup;
            // for (let i = 0; i <= this.weekly_price.length; i++) {
            //  if(this.month_price !== null){
            //   this.month=true;
            // }
            //  }
            //  this.daily_price = this.getProductListQuery.productListDailyGroup;
            //  if(this.daily_price !== null){
            //   this.daily=true;
            // }
           }
        }
      }
    );
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  previousPage() {
    if(this.offset > 0) {
      this.offset = (this.offset as number) - (this.page_count as number);
      this.filterSearchComponent.setOffsetValue(this.offset);
      this.filterSearchComponent.productList();
    }
  }

  nextPage() {
    this.offset = (this.offset as number) + (this.page_count as number);
    this.filterSearchComponent.setOffsetValue(this.offset);
    this.filterSearchComponent.productList();
  }
}
