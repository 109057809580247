import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { GetDayTemplatesQuery } from "src/app/services/graphql/getDayTemplates.graphql";
import { GetWeekTemplatesQuery } from "src/app/services/graphql/getWeekTemplates.graphql";
import { GetProductSchedulesQuery } from "src/app/services/graphql/getProductSchedules.graphql";
import { CreateScheduleMutation } from "src/app/services/graphql/createSchedule.graphql";
import { UpdateScheduleMutation } from "src/app/services/graphql/updateSchedule.graphql";
import { GetCategoryQuery } from "src/app/services/graphql/getcategory.graphql";
import { Apollo } from "apollo-angular";
import { ToastrService } from 'ngx-toastr';
import {NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from "src/app/services/session.service";
import { BehaviorSubject } from "rxjs";

import {
  faUser,
  faAddressBook,
  faCreditCard,
  faChevronLeft,
  faChevronRight,
  faSave,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationConstants } from "src/app/constants/app.constants";

@Component({
  selector: 'app-add-schedules',
  templateUrl: './add-schedules.component.html',
  styleUrls: ['./add-schedules.component.scss']
})
export class AddSchedulesComponent implements OnInit {

  model: NgbDateStruct;

  @Input()
  isEditable = true;

  @Input()
  scheduleIDAsync = new BehaviorSubject<number>(
    0
  );

  autoRenewalFlag = true;

  scheduleID = 0;
  scheduleData: any = null;
  updateScheduleFlag = false;
  calculatedPrice = new BehaviorSubject<number>(
    null
  );

  @Output("parentStepper") parentStepper: EventEmitter<any> = new EventEmitter();
  @Output("parentLoader") parentLoader: EventEmitter<any> = new EventEmitter();


  @Input()
  productID = null;

  @Input()
  categoryID = new BehaviorSubject<number>(
    0
  );
  categoryData = new BehaviorSubject<any>(
    null
  );

  flatCategoryData = null;

  faChevronLeft = faChevronLeft;
  faPerson = faUser;
  faAddressBook = faAddressBook;
  faCreditCard = faCreditCard;
  faChevronRight = faChevronRight;
  faSave = faSave;
  faBan = faBan;

  templateData = null;
  weekTemplateData = [];
  weekTemplateLoadAsync = new BehaviorSubject<boolean>(
    false
  );
  dayTemplateLoadAsync = new BehaviorSubject<boolean>(
    false
  );
  dayTemplateData = [];
  selectedTemplates = [];
  noTemplates = true;
  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  numOnlyRegex = /^[0-9]\d*$/;
  invalidSchedule = true;
  @Input() scheduleDisableNext = true;

  frmCreateSchedule = this.fb.group({
    scheduleName: ["Schedule " + (new Date).toLocaleString(), Validators.compose([Validators.required])],
    scheduleFrequency: [null, Validators.compose([Validators.required])],
    schedulePrice: [null, Validators.compose([Validators.required, Validators.pattern(this.numRegex)])],
    scheduleTemplateType: [null],
    scheduleTemplateSelect: [null],
    templateStartDate: [null],
    templateEndDate: [null],
    autoRenewal: [true],
    scheduleBufferDays: [null, Validators.compose([Validators.pattern(this.numOnlyRegex)])],
    scheduleTotalCount: [null, Validators.compose([Validators.pattern(this.numOnlyRegex)])],
    scheduleGuidelines: [null],
    scheduleCanFullRefund: [5, Validators.compose([Validators.pattern(this.numOnlyRegex)])],
    scheduleCanHalfRefund: [2, Validators.compose([Validators.pattern(this.numOnlyRegex)])]
  });

  constructor(
    private fb: FormBuilder,
    private getDayTemplatesQuery: GetDayTemplatesQuery,
    private getWeekTemplatesQuery: GetWeekTemplatesQuery,
    private apollo: Apollo,
    private toastr: ToastrService,
    private calendar: NgbCalendar,
    private createScheduleMutation: CreateScheduleMutation,
    private updateScheduleMutation: UpdateScheduleMutation,
    private sessionService: SessionService,
    private getProductSchedulesQuery: GetProductSchedulesQuery,
    private getCategoryQuery: GetCategoryQuery
    ) { }

  ngOnInit(): void {

    this.scheduleIDAsync.subscribe(
      (data) => {
        if(data != null && data != 0) {
          this.updateScheduleFlag = true;
          this.scheduleID = data;
  
          this.getSchedules();
        }
      }
    );

    this.categoryID.subscribe(
      (data) => {
        if(data != null && data != 0) {
          this.apollo
          .query({
            query: this.getCategoryQuery.document,
            variables: {
              category_id: data
            },
            fetchPolicy: "network-only"
          })
          .subscribe(
            ({ data }) => {
              this.categoryData.next(data["getProductCategories"][0]);
              this.flatCategoryData = data["getProductCategories"][0];
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    );
  }

  loadUpdateSchedule() {
    if(this.updateScheduleFlag === true) {
      this.frmCreateSchedule.patchValue({
        scheduleName: this.scheduleData["schedule_name"],
        scheduleFrequency: this.scheduleData["schedule_frequency"],
        schedulePrice: this.scheduleData["price"],
        scheduleBufferDays: this.scheduleData["buffer_days"],
        scheduleTotalCount: this.scheduleData["product_count"],
        scheduleGuidelines: this.scheduleData["guidelines"],
        autoRenewal: this.scheduleData["auto_renewal"]
      });

      this.autoRenewalFlag = this.scheduleData["auto_renewal"];

      if(this.scheduleData["cancellation_policies"] != null && this.scheduleData["cancellation_policies"].length > 0) {
        this.scheduleData["cancellation_policies"].forEach(canPolicy => {
          if(canPolicy["type"] == "full_refund") {
            this.frmCreateSchedule.patchValue({
              scheduleCanFullRefund: canPolicy["days"]
            });
          }

          if(canPolicy["type"] == "half_refund") {
            this.frmCreateSchedule.patchValue({
              scheduleCanHalfRefund: canPolicy["days"]
            });
          }
        });
      }

      this.selectedTemplates = [];

      this.queryDayTemplate();
      this.queryWeekTemplate();

        this.dayTemplateLoadAsync.subscribe(
          (data1) => {
            if(data1 === true) {
              this.weekTemplateLoadAsync.subscribe(
                (data2) => {
                  if(data2 === true) {
                      this.scheduleData["slots"].forEach(slot => {

                      let templateNameTemp = null;
                      if(slot["template_type"] === "day") {
                        this.dayTemplateData.forEach(template => {
                          if(template["day_template_id"] === slot["template_id"]) {
                            templateNameTemp = template["template_name"];
                          }
                        });
                      }
              
                      if(slot["template_type"] === "week") {
                        this.weekTemplateData.forEach(template => {
                          if(template["week_template_id"] === slot["template_id"]) {
                            templateNameTemp = template["template_name"];
                          }
                        });
                      }

                      this.selectedTemplates.push({
                        template_name: templateNameTemp,
                        template_type: slot["template_type"],
                        start_date: slot["date"],
                        start_year: slot["date"].split("-")[0],
                        start_month: slot["date"].split("-")[1],
                        start_day: slot["date"].split("-")[2],
                        end_date: slot["date"],
                        end_year: slot["date"].split("-")[0],
                        end_month: slot["date"].split("-")[1],
                        end_day: slot["date"].split("-")[2],
                        template_id: slot["template_id"]
                      });
                    });
              
                    if(this.selectedTemplates == null || this.selectedTemplates.length <= 0) {
                      this.noTemplates = true;
                    } else {
                      this.noTemplates = false;
                    }
                    this.invalidSchedule = false;
                  }
                }
              )
            }
          }
        );

      this.frmCreateSchedule.controls.scheduleName.disable();
    }
  }

  getSchedules() {
    if(this.scheduleID !== 0) {

      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");

      this.apollo
      .query({
        query: this.getProductSchedulesQuery.document,
        variables: {
          productId: <number>this.productID,
          idToken: token,
getDaySlots: false,
          lenderEmail: email,
          scheduleId: this.scheduleID
        },
        fetchPolicy: "network-only"
      }).subscribe(
        ({ data }) => {
          if (data["getProductSchedules"]) {
            this.scheduleData = data["getProductSchedules"][0];
            this.loadUpdateSchedule();
          }
        });
    }
  }

  templateChange($evt) {
    this.changeForm();
    let value = $evt.currentTarget.options[$evt.currentTarget.options.selectedIndex].value;

    if(value == "day") {
      this.queryDayTemplate();
    } else if(value == "week") {
      this.queryWeekTemplate();
    }
    this.frmCreateSchedule.controls.scheduleTemplateSelect.reset();
  }

  queryWeekTemplate() {
    if(this.productID != null && this.productID != 0) {
      this.apollo
      .mutate({
        mutation: this.getWeekTemplatesQuery.document,
        variables: {
          productId: this.productID
          }
      })
      .subscribe(
        ({ data }) => {
          this.templateData = data["getProductWeekTemplates"];
          this.weekTemplateData = data["getProductWeekTemplates"];
          this.weekTemplateLoadAsync.next(true);
        },
        (error) => {
          this.toastr.error(error.message);
          this.weekTemplateLoadAsync.next(false);
        }
      );
    }
  }

  queryDayTemplate() {
    this.apollo
      .mutate({
        mutation: this.getDayTemplatesQuery.document,
        variables: {
          productId: this.productID
          }
      })
      .subscribe(
        ({ data }) => {
          this.templateData = data["getProductDayTemplates"];
          this.dayTemplateData = data["getProductDayTemplates"];
          this.dayTemplateLoadAsync.next(true);
        },
        (error) => {
          this.dayTemplateLoadAsync.next(false);
          this.toastr.error(error.message);
        }
      );
  }

  getTemplateId(template) {
    if(template["day_template_id"] != null) {
      return "day_" + template["day_template_id"];
    }

    if(template["week_template_id"] != null) {
      return "week_" + template["week_template_id"];
    }

    return null;
  }


  scheduleIsDisabled = (date: NgbDate) => {
    this.changeForm();
    let currStartDate = new Date(date["year"], date["month"] - 1, date["day"]);

    let today = new Date();
    let yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);

    if(currStartDate < yesterday) {
      return true;
    }
  }

  isDisabled = (date: NgbDate) => {
    this.changeForm();
    let currStartDate = new Date(date["year"], date["month"] - 1, date["day"]);

    let today = new Date();
    let yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);

    if(currStartDate < yesterday) {
      return true;
    }

    let flag = false;

    this.selectedTemplates.forEach(template => {
      let sd = new Date(template["start_year"], template["start_month"] - 1, template["start_day"]);
      let ed = new Date(template["end_year"], template["end_month"] - 1, template["end_day"]);

      if(sd <= currStartDate && currStartDate <= ed) {
        flag = true;
      }
    });

    return flag;
  };

  deleteTemplateRef(templateId, templateType, startDate, endDate) {
    
    this.selectedTemplates.forEach((template, index) => {
      if(template["template_id"] == templateId && 
      template["template_type"] == templateType && 
      template["start_date"] == startDate && 
      template["end_date"] == endDate) {
        this.selectedTemplates.splice(index, 1);
      }
    });

    if(this.selectedTemplates == null || this.selectedTemplates.length <= 0) {
      this.noTemplates = true;
    }
  }

  addTemplatesTable() {

    if(this.frmCreateSchedule.controls.scheduleTemplateType.value == null || 
      this.frmCreateSchedule.controls.scheduleTemplateType.value == "NA") {
      this.toastr.error("Please select a Template type for adding a slot for your schedule.");
      return null;
    }

    if(this.frmCreateSchedule.controls.scheduleTemplateSelect.value == null || 
      this.frmCreateSchedule.controls.scheduleTemplateSelect.value == "0") {
      this.toastr.error("Please select a Template for adding a slot for your schedule.");
      return null;
    }

    if(this.frmCreateSchedule.controls.templateStartDate.value == null) {
      this.toastr.error("Please select a start date for adding a slot for your schedule.");
      return null;
    }

    if(this.frmCreateSchedule.controls.templateEndDate.value == null) {
      this.toastr.error("Please select a end date for adding a slot for your schedule.");
      return null;
    }

    let startDate = new Date(this.frmCreateSchedule.controls.templateStartDate.value.year, 
      this.frmCreateSchedule.controls.templateStartDate.value.month - 1, 
      this.frmCreateSchedule.controls.templateStartDate.value.day);

    let endDate = new Date(this.frmCreateSchedule.controls.templateEndDate.value.year,
      this.frmCreateSchedule.controls.templateEndDate.value.month - 1,
      this.frmCreateSchedule.controls.templateEndDate.value.day);


    if(startDate > endDate) {
        this.toastr.error("Start date for slot cannot be greater than end date.");
        return null;
    }

    let flag = "false";

    this.selectedTemplates.forEach(template => {
      let templateStartDate = new Date(template["start_year"], template["start_month"] - 1, template["start_day"]);
      let templateEndDate = new Date(template["end_year"], template["end_month"] - 1, template["end_day"]);

      if((templateStartDate <= startDate && templateEndDate >= startDate) || 
      (templateStartDate <= endDate && templateEndDate >= endDate) || 
      (startDate <= templateStartDate && endDate >= templateStartDate) || 
      (startDate <= templateEndDate && endDate >= templateEndDate)) {
        flag = "true";
      }
    });

    if(flag == "true") {
      this.toastr.error("Overlapping dates found for slots.");
      return null;
    }



    let startDateStr = this.frmCreateSchedule.controls.templateStartDate.value.year + "-" + 
    this.frmCreateSchedule.controls.templateStartDate.value.month + "-" + 
    this.frmCreateSchedule.controls.templateStartDate.value.day;

    let endDateStr = this.frmCreateSchedule.controls.templateEndDate.value.year + "-" + 
    this.frmCreateSchedule.controls.templateEndDate.value.month + "-" + 
    this.frmCreateSchedule.controls.templateEndDate.value.day;

    this.selectedTemplates.push({template_id: this.frmCreateSchedule.controls.scheduleTemplateSelect.value.split("_")[1],
      template_name: (document.getElementById("scheduleTemplateSelect")  as HTMLSelectElement).options[(document.getElementById("scheduleTemplateSelect") as HTMLSelectElement).options.selectedIndex].innerHTML,
      template_type: this.frmCreateSchedule.controls.scheduleTemplateType.value,
      start_date: startDateStr,
      start_year: this.frmCreateSchedule.controls.templateStartDate.value.year,
      start_month: this.frmCreateSchedule.controls.templateStartDate.value.month,
      start_day: this.frmCreateSchedule.controls.templateStartDate.value.day,
    end_date: endDateStr,
  end_year: this.frmCreateSchedule.controls.templateEndDate.value.year, 
end_month: this.frmCreateSchedule.controls.templateEndDate.value.month,
end_day: this.frmCreateSchedule.controls.templateEndDate.value.day});

    this.noTemplates = false;

    this.changeForm();

  }

  upsertSchedule() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    var scheduleName = null;
    var scheduleFrequency = null;
    var schedulePrice = null;
    var startDate = null;
    var endDate = null;
    var bufferDays = null;
    var productCount = null;
    var guidelines = null;
    var autoRenewal = null;
    var slots = [];
    var cancellationPolicies = null;

    if((this.frmCreateSchedule.invalid == false || this.updateScheduleFlag === true) && 
      this.selectedTemplates != null && 
      this.selectedTemplates.length > 0) {
      this.invalidSchedule = false;
      scheduleName = this.frmCreateSchedule.controls.scheduleName.value;
      scheduleFrequency = this.frmCreateSchedule.controls.scheduleFrequency.value;
      schedulePrice = this.frmCreateSchedule.controls.schedulePrice.value;
      bufferDays = this.frmCreateSchedule.controls.scheduleBufferDays.value;
      productCount = this.frmCreateSchedule.controls.scheduleTotalCount.value;
      guidelines = this.frmCreateSchedule.controls.scheduleGuidelines.value;
    } else {
      this.invalidSchedule = true;
      this.toastr.error("Details passed is not sufficient to create a schedule.");
      return null;
    }

    var start_date_temp = null;
    var end_date_temp = null;

    this.selectedTemplates.forEach(template => {
      if(start_date_temp == null) {
        start_date_temp = new Date(template["start_year"], template["start_month"] - 1, template["start_day"]);
      } else if(start_date_temp != null && start_date_temp > (new Date(template["start_year"], template["start_month"] - 1, template["start_day"]))) {
        start_date_temp = new Date(template["start_year"], template["start_month"] - 1, template["start_day"]);
      }
      if(end_date_temp == null) {
        end_date_temp = new Date(template["end_year"], template["end_month"] - 1, template["end_day"]);
      } else if(end_date_temp != null && end_date_temp < (new Date(template["end_year"], template["end_month"] - 1, template["end_day"]))) {
        end_date_temp = new Date(template["end_year"], template["end_month"] - 1, template["end_day"]);
      }
      slots.push({start_date: template["start_year"] + "-" + template["start_month"] + "-" + template["start_day"],
      end_date: template["end_year"] + "-" + template["end_month"] + "-" + template["end_day"],
      template_type: template["template_type"],
      template_id: template["template_id"]})
    });

    if(this.frmCreateSchedule.controls.scheduleCanFullRefund.value != null && 
      this.frmCreateSchedule.controls.scheduleCanFullRefund.value != "") {
        if(cancellationPolicies == null) cancellationPolicies = [];
        cancellationPolicies.push({type: "full_refund", days: this.frmCreateSchedule.controls.scheduleCanFullRefund.value});
    }

    if(this.frmCreateSchedule.controls.scheduleCanHalfRefund.value != null && 
      this.frmCreateSchedule.controls.scheduleCanHalfRefund.value != "") {
        if(cancellationPolicies == null) cancellationPolicies = [];
        cancellationPolicies.push({type: "half_refund", days: this.frmCreateSchedule.controls.scheduleCanHalfRefund.value});
    }

    if(this.frmCreateSchedule.controls.autoRenewal.value != null && 
      this.frmCreateSchedule.controls.autoRenewal.value != "" && 
      this.flatCategoryData != null && 
      this.flatCategoryData["auto_renewal_possible"] == true) {
        autoRenewal = this.frmCreateSchedule.controls.autoRenewal.value;
    }

    if(this.updateScheduleFlag === false) {
      this.apollo
      .mutate({
        mutation: this.createScheduleMutation.document,
        variables: {
          lender_email: email,
          id_token: token,
          schedule_name: scheduleName,
          product_id: this.productID,
          schedule_frequency: scheduleFrequency,
          price: schedulePrice,
          auto_renewal: autoRenewal,
          start_date: start_date_temp.getFullYear() + "-" + String(parseInt(start_date_temp.getMonth()) + 1) + "-" + start_date_temp.getDate(),
          end_date: end_date_temp.getFullYear() + "-" + String(parseInt(end_date_temp.getMonth()) + 1) + "-" + end_date_temp.getDate(),
          buffer_days: bufferDays,
          product_count: productCount,
          guidelines: guidelines,
          slots: slots,
          cancellation_policies: cancellationPolicies
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Schedule created successfully for your offer.");
          this.parentLoader.emit();
          this.scheduleDisableNext = false;
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    } else if(this.updateScheduleFlag === true) {
      this.apollo
      .mutate({
        mutation: this.updateScheduleMutation.document,
        variables: {
          schedule_id: this.scheduleID,
          lender_email: email,
          id_token: token,
          schedule_name: scheduleName,
          product_id: this.productID,
          schedule_frequency: scheduleFrequency,
          price: schedulePrice,
          auto_renewal: autoRenewal,
          start_date: start_date_temp.getFullYear() + "-" + String(parseInt(start_date_temp.getMonth()) + 1) + "-" + start_date_temp.getDate(),
          end_date: end_date_temp.getFullYear() + "-" + String(parseInt(end_date_temp.getMonth()) + 1) + "-" + end_date_temp.getDate(),
          buffer_days: bufferDays,
          product_count: productCount,
          guidelines: guidelines,
          slots: slots,
          cancellation_policies: cancellationPolicies
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Schedule recreated successfully for your offer.");
          this.parentLoader.emit();
          this.scheduleDisableNext = false;
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

  callParent() {
    this.parentStepper.emit();
  }

  getCalculatedPrice() {
    if(!this.frmCreateSchedule.get('schedulePrice').hasError('pattern')) {
      this.calculatedPrice.next(Math.ceil(+this.frmCreateSchedule.controls.schedulePrice.value * ((ApplicationConstants.CONST_COMMISSION_PERCENTAGE / 100) + 1)));
    }
    this.changeForm();  
  }

  changeForm() {
    if(this.frmCreateSchedule.invalid == false && 
      this.selectedTemplates != null && 
      this.selectedTemplates.length > 0) {
        this.invalidSchedule = false;
      } else {
        this.invalidSchedule = true;
      }
  }

}
